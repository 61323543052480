import { CustomColumnMenuFilter } from "../../../../ui/grid/CustomColumnMenu";
export const columnMenuConfig = {
    CustomColumnMenuFilter,
};

const columns = [


    {
        title: "WORK ORDER ID",
        field: "workOrderId",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        orderIndex:0
    },
    {
        title: "STUDY NAME",
        field: "studyName",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        orderIndex:1
    },
    {
        title: "YEAR",
        field: "year",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        orderIndex:2
    },
    
    {
        title: "WEEK",
        field: "week",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        orderIndex:3
    },
    {
        title: "PLAN TYPE",
        field: "planType",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        orderIndex:4
    },
    {
        title: "STATUS",
        field: "status",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        orderIndex:5
    },
    
    {
        title: "REQUESTED USER EMAIL",
        field: "requestedUser",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        orderIndex:6
    },
    {
        title: "REQUESTED TIMESTAMP",
        field: "requestedTimestamp",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        orderIndex:7
    },
    {
        title: "REQUEST COMPLETE TIMESTAMP",
        field: "requestCompletionTimestamp",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        orderIndex:8
    },

    {
        title: "Action",
        field: "action",
        show: true,
        orderIndex:9
    },
];
export default columns;
