import { CustomColumnMenuFilter } from "../../../ui/grid/CustomColumnMenu";
export const columnMenuConfig = {
    CustomColumnMenuFilter,
};

const columns = [
    {
        title:  "jobid",
        field: "WorkOrderID",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title:  "countryname",
        field: "Country",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "Year",
        field: "Year",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130,
        orderIndex:0
    },
    {
        title: "Week",
        field: "Week",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title:  "Barcode",
        field: "Barcode",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        // format: "{0:MM/dd/yyyy}",
        width: 130
    },
    {
        title: "CodingType",
        field: "CodingType",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "City",
        field: "City",
        show: true,
        columnMenu: "CustomColumnMenuFilter", 
        width: 130
        
    },
    {
        title: "State",
        field: "State",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
        
    },
    {
        title: "statuscode",
        field: "StatusCode",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
        
    },
    {
        title: "Sms Id",
        field: "StoreID",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width:200
    },
    {
        title: "auditorid",
        field: "AuditorID",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width:200
    },
    {
        title: "itemcompletionproductdescripiton",
        field: "ItemCompletionProductDescripiton",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width:200
    },
    {
        title: "itemcompletionmodule",
        field: "ItemCompletionModule",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width:200
    },
    {
        title: "ImageCount",
        field: "ImageCount",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width:200
    },
    {
        title: "GenerationWeek",
        field: "GenerationWeek",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "timestampofscanned",
        field: "TimestampofScanned",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "datecoded",
        field: "DateCoded",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "date",
        width: 130
    },
    {
        title: "datereceived",
        field: "DateReceived",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "date",
        width: 130
    },
    {
        title: "Comments",
        field: "Comments",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "PromotedPrice",
        field: "PromotedPrice",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title: "Store Closure Comment",
        field: "StoreClosureComment",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    

];
export default columns;
