
import { Get, Post, PostwithTimeoutHandler } from "../../../../../store/methods";
import AppURL from "../../../../../utils/AppURL";
import { showToast } from "../../../../../utils/Notification";

// const getHeaders = (authContext) => ({
//   OrganizationUnit: authContext?.organizationUnit || "",
//   userid: authContext?.SpocMail || "",
// });


export const getSummaryReport = async (params, authContext) => {

  const header = {

    userid: authContext?.SpocMail || "",
    countryid: authContext?.CountryCode || "",
    companyid: authContext?.CompanyID || "",
    "content-type": "application/json",
    // "content-type": "application/x-www-form-urlencoded; application/json",
  }

  const res = await Post(AppURL.getSummaryReport, params, header, true).then((res) =>
    // const res = await Get(AppURL.getSummaryReport, params, header,true).then((res) =>
    res.json()
  );
  return res;


};

//Export

export const getExportData = async (params, authContext) => {

  const header = {

    // userid: authContext?.SpocMail || "",
    // countryid: authContext?.CountryCode || "",
    // companyid: authContext?.CompanyID || "",
    // "content-type": "application/x-www-form-urlencoded; application/json",
    "content-type": "application/json",
  }

  try {
    const exportFile = await Get(AppURL.getExportData, params, header, true);
    console.log("exportFile", exportFile);
    if (exportFile.status === 200) {
      // console.log("exportFile", exportFile?.headers?.get("Content-Disposition"))
      console.log("AfterAPi", Date.now());
      const disposition = exportFile?.headers
        ?.get("Content-Disposition")
        ?.split("filename=");
      const exportFileBlob = await exportFile.blob();
      const exportFileUrl = URL.createObjectURL(exportFileBlob);
      // const FILE_NAME = Date.now();
      const anchor = document.createElement("a");
      anchor.href = exportFileUrl;
      anchor.download = disposition?.[1];
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      showToast(
        "success",
        "File exported successfully",
      );
    } else {
      showToast(
        "error",
        "File Export failed, please try again later or with fewer records",
      );
    }
  } catch (error) {
    console.log("errorcatch", error);
    showToast(
      "error",
      "File Export failed, please try again later or with fewer records",
    );
  }
};

export const getExportDataWithPostTimeoutHandler = async (params, authContext) => {

  const header = {

    // userid: authContext?.SpocMail || "",
    // countryid: authContext?.CountryCode || "",
    // companyid: authContext?.CompanyID || "",
    // "content-type": "application/x-www-form-urlencoded; application/json",
    "content-type": "application/json",
  }

  try {
    const exportFile = await PostwithTimeoutHandler(AppURL.reportsAPI, params, header, false,10000);
    console.log("exportFile", exportFile);
    if (exportFile.status === 200) {
      // console.log("exportFile", exportFile?.headers?.get("Content-Disposition"))
      console.log("AfterAPi", Date.now());
      const disposition = exportFile?.headers
        ?.get("Content-Disposition")
        ?.split("filename=");
      const exportFileBlob = await exportFile.blob();
      const exportFileUrl = URL.createObjectURL(exportFileBlob);
      
      // Ensure the filename is present and clean it up
      let fileName = disposition?.[1] ? disposition[1].trim() : `file_${Date.now()}.csv`;
      

      // If the filename is surrounded by quotes, remove them
      fileName = fileName.replace(/^"|"$/g, '');

      // Extract the report name (everything before the first underscore)
      const reportName = fileName.split('_')[0];

      // const FILE_NAME = Date.now();
      const anchor = document.createElement("a");
      anchor.href = exportFileUrl;
      anchor.download = `${reportName}_${Date.now()}.csv`;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      showToast(
        "success",
        "File exported successfully",
      );
    } else {
      showToast(
        "error",
        "File Export failed, please try again later or with fewer records",
      );
    }
  } catch (error) {
    console.log("errorcatch", error);
    showToast(
      "error",
      "File Export failed, please try again later or with fewer records",
    );
  }
};

export const getExportDataWithPostTimeoutHandlerZip = async (params, authContext, recievedfileName) => {

  const header = {

    // userid: authContext?.SpocMail || "",
    // countryid: authContext?.CountryCode || "",
    // companyid: authContext?.CompanyID || "",
    // "content-type": "application/x-www-form-urlencoded; application/json",
    "content-type": "application/json",
  }

  try {
    const exportFile = await PostwithTimeoutHandler(AppURL.reportsAPI, params, header, false,10000);
    console.log("exportFile", exportFile);
    if (exportFile.status === 200) {
      // console.log("exportFile", exportFile?.headers?.get("Content-Disposition"))
      console.log("AfterAPi", Date.now());
      const disposition = exportFile?.headers
        ?.get("Content-Disposition")
        ?.split("filename=");
      const exportFileBlob = await exportFile.blob();
      const exportFileUrl = URL.createObjectURL(exportFileBlob);
      
      // Ensure the filename is present and clean it up
      let fileName = disposition?.[1] ? disposition[1].trim() : `file_${Date.now()}.zip`;
      

      // If the filename is surrounded by quotes, remove them
      fileName = fileName.replace(/^"|"$/g, '');

      // Extract the report name (everything before the first underscore)
      const reportName = fileName.split('_')[0];

      // const FILE_NAME = Date.now();
      const anchor = document.createElement("a");
      anchor.href = exportFileUrl;
      anchor.download = recievedfileName !== null ? recievedfileName : `${reportName}_${Date.now()}.zip`;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      showToast(
        "success",
        "File exported successfully",
      );
    } else {
      showToast(
        "error",
        "File Export failed, please try again later or with fewer records",
      );
    }
  } catch (error) {
    console.log("errorcatch", error);
    showToast(
      "error",
      "File Export failed, please try again later or with fewer records",
    );
  }
};


// export const getExportData = async (params, authContext) => {

//   const header = {

//     // userid: authContext?.SpocMail || "",
//     // countryid: authContext?.CountryCode || "",
//     // companyid: authContext?.CompanyID || "",
//     // "content-type": "application/x-www-form-urlencoded; application/json",
//     "content-type": "application/json",
//   }

//   // const exportFile = await Get(AppURL.getExportData,params,null,true);    
//   const exportFile = await Post(AppURL.getExportData,params,header,null,true);    
//     console.log("exportFile", exportFile?.headers?.get("Content-Disposition"))
//     const disposition = exportFile?.headers.get("Content-Disposition")?.split("filename=")
//     const exportFileBlob = await exportFile.blob();
//     const exportFileUrl = URL.createObjectURL(exportFileBlob);    
//     // const FILE_NAME = Date.now();
//     const anchor = document.createElement("a");
//     anchor.href = exportFileUrl; 
//     anchor.download = disposition?.[1];
//     document.body.appendChild(anchor);
//     anchor.click();
//     document.body.removeChild(anchor);  


// };


export const getEcollectionSummaryReport = async (params, authContext) => {

  const header = {

    SpocMail: authContext?.SpocMail || "",
    // countryid: authContext?.CountryCode || "",
    CompanyID: authContext?.CompanyID || "",
    "content-type": "application/json",
    // "content-type": "application/x-www-form-urlencoded; application/json",
  }

  const res = await PostwithTimeoutHandler(AppURL.reportsAPI, params, header, true, 10000).then((res) =>
    // const res = await Get(AppURL.getSummaryReport, params, header,true).then((res) =>
    res.json()
  );
  return res;


};

