const progressColumns = [
  {
    title: "WEEK ID",
    // field: "weekId",
    show: false,
    // width: 115,
    editable: false,
  },
  {
    title: "WEEK ID",
    field: "weekId",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "STORE ID",
    field: "smsId",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "STORE NAME",
    field: "storeName",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "STORE STATUS",
    field: "storeStatus",
    show: true,
    width: 130,
    // editable: false,
  },
  {
    title: "STUDY ID",
    field: "studyId",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "STUDY NAME",
    field: "studyName",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "REGION",
    field: "region",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "PROVINCE",
    field: "province",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "CITY",
    field: "city",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "BANNER",
    field: "banner",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "COLLECTION TYPE",
    field: "collectionType",
    show: true,
    width: 152,
    editable: false,
  },
  {
    title: "LIMIT DATE",
    field: "limitDate",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "LIMIT TIME",
    field: "limitTime",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "TX DATE",
    field: "txDate",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "TX TIME",
    field: "txTime",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "STORE RATING",
    field: "storeRating",
    show: true,
    width: 130,
    editable: false,
  },
  {
    title: "W0 (Unique)",
    field: "w0",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "W1",
    field: "w1",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "W2",
    field: "w2",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "W3",
    field: "w3",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "# OF VISITS",
    field: "noOfVisits",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "TOTAL CATEGORIES",
    field: "totalCategories",
    show: true,
    width: 160,
    editable: false,
  },
  {
    title: "TOTAL EANS",
    field: "totalEans",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "DUPLICATE EANS",
    field: "duplicateEans",
    show: true,
    width: 115,
    editable: false,
  },

  {
    title: "WITH PROMO",
    field: "withPromo",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "NO PRICE",
    field: "noPrice",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "UNKNOWN EANS",
    field: "unknown",
    show: true,
    width: 140,
    editable: false,
  },
  {
    title: "NOTE VENDOR",
    field: "vendorNotes",
    show: true,
    width: 140,
    // cell: StoreStatusCombo,
  },
  {
    title: "NOTE NIQ",
    field: "niqNotes",
    show: true,
    width: 115,
  },
];
export default progressColumns;
