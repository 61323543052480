import { CustomColumnMenuFilter } from "../../../../ui/grid/CustomColumnMenu";
export const columnMenuConfig = {
    CustomColumnMenuFilter,
  };

const columns = [
    {
        title: "surveyId",
        field: "survey_id",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        filter: "numeric",
        width: 130
    },
    {
        title:"STORE_NAME",
        field: "store_name",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "STORE_ID",
        field: "store_id",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "Block_Id",
        field: "block_id",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title:  "store_address",
        field: "store_address",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "city_lbl",
        field: "city",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "PROVINCE",
        field: "province",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "Cdar User Id",
        field: "cdar_user_id",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
        // cell:UploadCell
    },
    {
        title: "Created By V1",
        field: "created_by_v1",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "starttime",
        field: "start_time",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "Submit Time",
        field: "submit_time",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "Sync Time",
        field: "sync_time",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "Server Sync Time",
        field: "server_sync_time",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "Response Status",
        field: "response_status",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "AppVersion",
        field: "app_version",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "Platform Model",
        field: "platform_model",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "Platform Version",
        field: "platform_version",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "Survey Duration",
        field: "surveyDurationV1",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "Geocoordinates",
        field: "Geocoordinates",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    {
        title: "storestatus",
        field: "storeStatus",
        show: true,
        columnMenu: "CustomColumnMenuFilter",
        width: 130
    },
    
];
export default columns;
