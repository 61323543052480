import React from 'react';
import "./eCollection.css";
import layer from '../../../assets/images/reports/layer.png';
import layer1 from '../../../assets/images/reports/layer1.png';
import layer2 from '../../../assets/images/reports/layer2.png';
import layer3 from '../../../assets/images/reports/layer3.png';
import layer3New from '../../../assets/images/reports/layer3_new.png';
import layer4 from '../../../assets/images/reports/layer4.png';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CardListItem = (props) => {
  const history = useHistory();

  return (
    <li className='card-container' onClick={() => history.push(props.character.path)}>
      <p className='all-reports-name'>
        {props.character.reportName}
      </p>
      <div style={{
        display: 'flex',
        alignItems: 'center'
      }}>
        <p>{props.character.reportImage}</p>
        <p>{props.character.navigateIcon}</p>
      </div>
    </li>
  );
};

const Ecollection = () => {
  const { t } = useTranslation();
  const [displayReport, setDisplayReport] = React.useState(false);
  const openReport = () => {
    console.log("click before", displayReport)
    setDisplayReport(!displayReport)
  }

  const characters = [
    {
      id: 1,
      reportName: t("ecollectionssummaryreport"),
      reportImage: <img className='img-style' src={layer1} alt="report" />,
      navigateIcon: <span
        className="k-icon k-i-chevron-right k-icon-32"
        style={{ fontSize: 29 }}
      />,
      path: "/reports/eCollection/summaryreport"
    },
    {
      id: 2,
      reportName: t("flowcontrolreport"),
      reportImage: <img className='img-style' src={layer2} alt="report" />,
      navigateIcon: <span
        className="k-icon k-i-chevron-right k-icon-32"
        style={{ fontSize: 29 }}
      />,
      path: "/reports/ecollection/flowcontrolreport"
    },
    // {
    //   id: 3,
    //   reportName: t("scanimagetimestamp"),
    //   reportImage: <img className='img-style' src={layer3} alt="report" />,
    //   navigateIcon: <span
    //     className="k-icon k-i-chevron-right k-icon-32"
    //     style={{ fontSize: 29 }}
    //   />,
    //   path: "/reports/ecollection/scanimagetimestamp"
    // },
    {
      id: 4,
      reportName: t("Scan-Image Timestamp"),
      reportImage: <img className='img-style' src={layer3New} alt="report" />,
      navigateIcon: <span
        className="k-icon k-i-chevron-right k-icon-32"
        style={{ fontSize: 29 }}
      />,
      path: "/reports/ecollection/scanimagetimestampnew"
    },
    {
      id: 5,
      reportName: t("QualityCheck"),
      reportImage: <img className='img-style' src={layer4} alt="report" />,
      navigateIcon: <span
        className="k-icon k-i-chevron-right k-icon-32"
        style={{ fontSize: 29 }}
      />,
      path: "/reports/ecollection/executionquality"
    },
  ];

  const [reports, setReports] = React.useState(characters);

  React.useEffect(() => {
    const nrps = {
      id: 5,
      reportName: t("NRPS Report"),
      reportImage: <img className='img-style' src={layer} alt="report" />,
      navigateIcon: <span
        className="k-icon k-i-chevron-right k-icon-32"
        style={{ fontSize: 29 }}
      />,
      path: "/reports/ecollection/nrps"
    }
    const nrps_required = atob(localStorage.getItem(btoa("nrps_required")));
    console.log("nrps_required", typeof nrps_required)
    if (nrps_required === "true") {
      setReports([...reports, nrps])
    }
  }, [])

  return (
    <ul style={{
      listStyleType: "none",
      padding: 0,
      display: 'flex',
      flexWrap: "wrap",
      gap: "30px",
    }}>
      {reports.map((character) => {
        return (<CardListItem character={character} key={character.id} openReport={openReport} />);
      })}
    </ul>
  );
}

export default Ecollection
