import AppURL from "../../../../../utils/AppURL";
import { Get, Post, PostwithTimeoutHandler } from "../../../../../store/methods";

// import DCTTABLERESOSPONSE from "./dctTable.json"

export const getDCTTableBasic = async(params, authContext) => {

    const header = {
        OrganizationUnit: authContext?.organizationUnit || "",
        userid: authContext?.SpocMail || "",
        countryid: authContext?.CountryCode || "",
        companyid:authContext?.CompanyID || "",
        "content-type": "application/json",
        // "content-type": "application/x-www-form-urlencoded; application/json",
      }
    
    // const res = await Get(AppURL.getDCTTableBasic, params, header,true).then((res) =>
    const res = await PostwithTimeoutHandler(AppURL.reportsAPI, params, header,true,20000).then((res) =>
      res.json()
    );
    return res;
    // return DCTTABLERESOSPONSE;
  };

  

  export const getDCTExportData = async (params, authContext) => {

    const header = {
      
    
    }
  
    // const exportFile = await Get(AppURL.getDCTExportData,params,null,true);    
    const exportFile = await Post(AppURL.getDCTExportData,params,null,true);    
      console.log("exportFile", exportFile?.headers?.get("Content-Disposition"))
      const disposition = exportFile?.headers.get("Content-Disposition")?.split("filename=")
      const exportFileBlob = await exportFile.blob();
      const exportFileUrl = URL.createObjectURL(exportFileBlob);    
      // const FILE_NAME = Date.now();
      const anchor = document.createElement("a");
      anchor.href = exportFileUrl; 
      anchor.download = disposition?.[1];
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor); 
  
    
  };
