const auditorColumns = [
  {
    title: "WEEK ID",
    field: "weekId",
    show: false,
    // width: 115,
    editable: false,
  },
  {
    title: "WEEK ID",
    field: "weekId",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "STORE ID",
    field: "smsId",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "STORE NAME",
    field: "storeName",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "STORE STATUS",
    field: "storeStatus",
    show: true,
    width: 130,
    // editable: false,
  },
  {
    title: "STUDY ID",
    field: "studyId",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "STUDY NAME",
    field: "studyName",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "REGION",
    field: "region",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "PROVINCE",
    field: "province",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "CITY",
    field: "city",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "BANNER",
    field: "banner",
    show: true,
    width: 115,
    editable: false,
  },
  // {
  //   title: "VENDOR",
  //   field: "vendor",
  //   show: true,
  //   width: 115,
  // },
  {
    title: "AUDITOR ID",
    field: "auditorId",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "COLLECTION TYPE",
    field: "collectionType",
    show: true,
    width: 152,
    editable: false,
  },
  {
    title: "LIMIT DATE",
    field: "limitDate",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "STORE RATING",
    field: "storeRating",
    show: true,
    width: 130,
    editable: false,
  },
  {
    title: "# OF VISITS",
    field: "noOfVisits",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "TOTAL CATEGORIES",
    field: "totalCategories",
    show: true,
    width: 160,
    editable: false,
  },
  {
    title: "EAN Per HOUR",
    field: "avgEanPerHour",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "TOTAL EANS",
    field: "totalEans",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "DUPLICATE EANS",
    field: "duplicateEans",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "WITH PROMO",
    field: "withPromo",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "NO PRICE",
    field: "noPrice",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "UNKNOWN EANS",
    field: "unknown",
    show: true,
    width: 140,
    editable: false,
  },
  {
    title: "NOTES(qty in the file)",
    field: "notesInQty",
    show: true,
    width: 170,
    // editable: false,
  },
];
export default auditorColumns;
