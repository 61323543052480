// import { Form } from "@progress/kendo-react-form";
import React, { useState, useEffect, useRef } from "react";
import "./index.scss";
import backgrndImg from "../../assets/img/login_backgrnd.png";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Checkbox } from "@progress/kendo-react-inputs";
import { useDispatch, useSelector } from "react-redux";

import miniloadigGIF from "../../assets/images/miniloading.gif"

import * as type from "../../store/actions/types";

import { MessageBox, showToast } from "../../utils/Notification";
import {
  inputValidator,
  emailValidator,
  loginEmailValidator,
  loginInputValidator,
  forgotPasswordValidator,
} from "../form/validator";
import peopleMgmtImage from "../../assets/img/people_management.png";
import workOrdersImage from "../../assets/img/work_orders.png";
import NielsenLogo from "../../assets/img/nielsen_logo.svg";
import NIQLogo from "../../assets/img/niq_logo.svg";

import {
  FormCheckbox,
  FormComboBox,
  FormInput,
  FormPasswordInput,
} from "../ui/form/form-component";

import { useLocalization } from "@progress/kendo-react-intl";
import {
  forgotPassword,
  forgotPasswordValidation,
  getcaptchaAsync,
  loginAsync,
  resetPassword,
} from "../../store/actions/login";
import { LoginPasswordInput } from "../ui/form/fields/input";
import { Input } from "@progress/kendo-react-inputs";
// import { useNavigate } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import LoadMask from "../../utils/LoadMask";
import { useTranslation } from "react-i18next";
import LanguageChange from "../home/LanguageChange";
import useLogout, { checkInvalidSession } from "../../utils/useLogout";
import MainFooter from "../main/mainFooter";
import { Post } from "../../store/methods";
import AppURL from "../../utils/AppURL";

const Login = () => {
  const updateFormRef = useRef(null)
  const emailInputRef = useRef(null);

  const { t } = useTranslation();
  const { handleAuthContext } = useLogout();
  const [emailId, setEmailId] = useState(getCookie("SpocMailId"));
  const [password, setPassword] = useState();
  const [checked, setChecked] = useState(false);
  // const [passwordType, setPasswordType] = useState("password");
  const [passwordType, setPasswordType] = useState("password");
  const [newPasswordType, setNewPasswordType] = useState();
  const [confirmPasswordType, setConfirmPasswordType] = useState();
  // const [showForgetPasswordForm, setShowForgetPasswordForm] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState("login");
  const [showLoader, setShowLoader] = useState(null);
  const [forgotPasswordQuestion, setForgotPasswordQuestion] = useState([]);
  const [forgotPwdValidResState, setForgotPwdValidResState] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [messageProps, setMessageProps] = useState(null);
  const [vendorListData, setVendorListData] = useState([]);
  const [vendorSelected, setVendorSelected] = useState({});
  const [apiCall, setAPICall] = useState(false);
  const [showsmallLoading, setshowsmallLoading] = useState(false)

  const authContext = useSelector((state) => state.commonReducer.authContext);
  const loginError = useSelector((state) => state.commonReducer.loginError);
  const forgotPasswordQus = useSelector(
    (state) => state.commonReducer.forgotPasswordQus
  );
  const forgotPasswordError = useSelector(
    (state) => state.commonReducer.forgotPasswordError
  );
  const forgotPasswordValidationResponse = useSelector(
    (state) => state.commonReducer.forgotPasswordValidationResponse
  );
  const resetPasswordResponse = useSelector(
    (state) => state.commonReducer.resetPasswordResponse
  );
  const forceLogin = useSelector((state) => state.commonReducer.forceLogin);

  const [loginAttempts, setLoginAttempts] = useState(1);
  const [captchaData, setCaptchaData] = useState(null);
  const [captchaValue, setCaptchaValue] = useState("");
  const [vendorIdData,setVendorIdData] = useState(null)

  const [intialvalueData, setInitialValueData] = useState({
    vendorlist: "",
    emailId: "",
    // password: ""
  })

  const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;

  const [correctEmail,setIsCorrectEmail]=useState(false)

  const localizationService = useLocalization();
  const dispatch = useDispatch();
  const history = useHistory();

  


  useEffect(() => {
    setShowLoader(null);
    if (loginError) {
      showToast(
        "error",
        localizationService.toLanguageString(
          "pleaseEnterMandatoryFields", // to be updated
          `${loginError}`
        )
      );
      console.log(loginError);
    }
  }, [loginError]);

  useEffect(() => {
    setShowLoader(null);
    if (resetPasswordResponse?.Status === "SUCCESS") {
      setShowLoginForm("login");
      dispatch({
        type: type.RESET_PASSWORD,
        payload: null,
      })
      dispatch({
        type: type.FORGOT_PASSWORD_QUS,
        payload: null,
      })
      dispatch({
        type: type.FORGOT_PASSWORD_VALIDATION_RESPONSE,
        payload: null,
      })
      showToast(
        "success",
        localizationService.toLanguageString(
          "pleaseEnterMandatoryFields", // to be updated
          `${resetPasswordResponse?.Message}`
        )
      );
    }
    if (resetPasswordResponse?.Status === "FAILURE") {
      showToast(
        "error",
        localizationService.toLanguageString(
          "pleaseEnterMandatoryFields", // to be updated
          `${resetPasswordResponse?.Message}`
        )
      );
    }
  }, [resetPasswordResponse]);

  useEffect(() => {
    if(correctEmail && apiCall){
      updateFormRef.current.resetForm()
    }
  }, [intialvalueData,apiCall])


  useEffect(async () => {

    if (correctEmail) {

      setshowsmallLoading(true)

      let headers = {
        'Accept': 'application/json',
        "Content-Type": "application/json"
      };

      let params = { "VendorMail": emailId };
      let res = await Post(AppURL.vendorlistData, JSON.stringify(params), headers, true).then((res) =>
        res.json()
      );
      console.log(res.Response, "123")
      if (res?.Response?.length > 0) {
        setVendorListData(res?.Response)
        // setVendorListData([{VendorId: "568", VendorName: "NRPS_TEST1"},{VendorId: "461", VendorName: "NRPS_TEST123"},{VendorId: "360", VendorName: "NRPSTUV_TEST123"}])
        setVendorSelected(res?.Response?.[0])
       
        //updateFormRef.current.resetForm()
        setInitialValueData({ ...intialvalueData, vendorlist: res?.Response?.[0].VendorName })
        setVendorIdData(parseInt(res?.Response?.[0].VendorId))
        updateFormRef.current.resetForm()
        if(res?.Response.length === 1){
          showToast("success","Single Vendor list data identified for the entered email address.");
        }else{
          if(res?.Response.length > 1){
            showToast("info","More than One Vendor list data identified for the entered email address.");
          }
        }
      } else {
        setVendorListData([])
        setVendorSelected({})
        showToast("warning","No Vendor list found for entered email address. Check email once again OR Try another."); 
        updateFormRef.current.resetForm()
        setInitialValueData({ vendorlist: "" })
      }
      setAPICall(false)
      setshowsmallLoading(false)
    }else{
      setVendorListData([])
      setVendorSelected({})
      setInitialValueData({ ...intialvalueData, vendorlist: "" })
    }
    // console.log(res, "123456des")

  }, [emailId])


  useEffect(() => {
    setShowLoader(null);
    if (forgotPasswordError) {
      showToast(
        "error",
        localizationService.toLanguageString(
          "pleaseEnterMandatoryFields", // to be updated
          `${forgotPasswordError}`
        )
      );
    }
  }, [forgotPasswordError]);

  useEffect(() => {
    setShowLoader(null);
    showToast(null);
    if (forgotPasswordValidationResponse?.Status === "FAILURE") {
      showToast(
        "error",
        localizationService.toLanguageString(
          "pleaseEnterMandatoryFields", // to be updated
          `${forgotPasswordValidationResponse?.Message}`
        )
      );
    }
    if (forgotPasswordValidationResponse?.Status === "SUCCESS") {
      setShowLoginForm("changePassword");
    }
  }, [forgotPasswordValidationResponse]);

  // React.useEffect(()=>{    
  //   setEmailId(getCookie("SpocMail"))
  // },[])

  function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  // function checkCookie() {
  //   let user = getCookie("username");
  //   if (user != "") {
  //     alert("Welcome again " + user);
  //   } else {
  //     user = prompt("Please enter your name:", "");
  //     if (user != "" && user != null) {
  //       setCookie("username", user, 365);
  //     }
  //   }
  // }

  const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  };

  const messagePopup = (msg, button, icon) => {
    const callBackFn = (msg) => {
      setMessageProps(null);
      if (msg === "YES") {
        setShowLoader({
          msg: localizationService.toLanguageString("loading", "Loading"),
        });
        // dispatch({ type: "FORCE_LOGIN", payload: "Y" });
        // dispatch(login(createLoginParams({ ForceLogin: "Y" })));
        handleLogin({ ForceLogin: "Y" });
      }
    };
    setMessageProps({
      title: ("_status", "Status"),
      buttons: button,
      icon: icon,
      message: (`_${msg}`, `${msg}`),
      fn: callBackFn,
    });
  };

  // useEffect(() => {
  //   setShowLoader(null);
  //   if (authContext) {
  //     console.log(`authContext`, authContext);
  //     console.log(`local Stoarage`, localStorage.getItem("spocDetails"));
  //     if (
  //       authContext?.SessionExists === true &&
  //       forceLogin === "N" &&
  //       !localStorage.getItem("spocDetails")
  //     ) {
  //       console.log(
  //         `${authContext?.SessionExists}: authContext?.SessionExists, ${forceLogin}: forceLogin`
  //       );
  //       messagePopup(
  //         "There is another session open for this user. Do you want to close that session and continue with the current login?",
  //         "YESNO",
  //         "WARNING"
  //       );
  //       console.log(authContext?.SessionExists);

  //       return;
  //     } else {
  //       // history.push(`/fmsnavigator`);
  //       setCookie("sessionid", authContext.SessionId);
  //       setCookie("CompanyID", authContext.CompanyID);
  //       setCookie("SpocMail", authContext.SpocMail);
  //       localStorage.setItem("spocDetails", JSON.stringify(authContext));
  //       // dispatch({ type: "FORCE_LOGIN", payload: false });
  //       // dispatch(fetchuserprofile({ SpocMailId: authContext?.SpocMail, CompanyId: authContext?.CompanyID }));
  //       // dispatch(fetchDashboardData({ CompanyId: authContext?.CompanyID, page: 1, start: 0, limit: 25 }));
  //       // dispatch(getMails({CompanyId: authContext?.CompanyID, page:1, startCount: 0, MaxCount: 25 }));
  //       history.push(`/`);
  //       // dispatch(fetchuserprofile({SpocMailId:"", CompanyId:""}));
  //       // console.log(localStorage.getItem('spocDetails'));
  //     }
  //   }
  // }, [authContext]);

  useEffect(() => {
    setShowLoader(null);
    if (forgotPasswordQus?.length) {
      console.log(authContext);
      setForgotPasswordQuestion(forgotPasswordQus);
      // setShowLoginForm((prevState) => !prevState);
      setShowLoginForm("forgot");
    }
  }, [forgotPasswordQus]);

  const languages = [
    "English",
    "Spanish",
    "German",
    "French",
    "Dutch",
    "Portuguese",
  ];

  const onLanguageChange = (event) => {
    console.log(event.value);
  };
  const createLoginParams = (props) => {
    return {
      Registration: {
        Email: emailId,
        Password: password,
        Type: "VENDOR",
        ForceLogin: props?.ForceLogin || "N",
        CompanyId:vendorIdData === null ? "": parseInt(vendorIdData)
      },
    };
  };
  const forgotPasswordParams = () => {
    return {
      Registration: {
        Email: emailId,
        Password: password ? password : "",
        Type: "VENDOR",
      },
    };
  };

  React.useEffect(() => {
    const onEmailChange = (e, formRenderProps) => {
      const emailId = formRenderProps.valueGetter("emailId");
      setEmailId(emailId);
    };
  }, [checked])

  const isValidEmail = (email) => {
    return emailRegex.test(email);
  };

  const onEmailChange = (e, formRenderProps) => {
    const emailId = formRenderProps.valueGetter("emailId");
    const correct = isValidEmail(emailId)
    setEmailId(emailId);
    console.log(correct,"isValidEmail")
    setIsCorrectEmail(correct)
    setInitialValueData({ ...intialvalueData, emailId: emailId })
  };

  const onPasswordChange = (e, formRenderProps) => {
    console.log(`onPasswordChange ${formRenderProps}`);
    const password = formRenderProps.valueGetter("password");
    setPassword(password);
    // setInitialValueData({ ...intialvalueData, password: password })
    console.log(password);
  };

  const newPasswordChange = (e, formRenderProps) => {
    setNewPassword(formRenderProps.valueGetter("newPassword"));
  };
  const confirmPasswordChange = (e, formRenderProps) => {
    setConfirmPassword(formRenderProps.valueGetter("confirmPassword"));
    console.log(formRenderProps);
  };
  const confirmPasswordValidator = (value) =>
    !value
      ? "This Field Is Required"
      : newPassword === value
        ? ""
        : `The minimum length for this field is 8
  Your Passwords Do Not Match.`;

  const handleLogin = async (params) => {
    setShowLoader({
      // className: ".charGrid",
      msg: localizationService.toLanguageString("loading", "Loading"),
    });
    // dispatch(login(createLoginParams()));
    const res = await loginAsync(createLoginParams(params));
    setShowLoader(null);
    console.log("handleLogin", { res });
    if (
      res?.Response?.Status === "SUCCESS"
    ) {
      localStorage.setItem(
        "coveragescreen_required",
        res?.Response?.CompanyDetails?.coveragescreen_required || null
      );

      localStorage.setItem(
        "iqct_flag",
        res?.Response?.CompanyDetails?.iqct_flag ?? null
      );

      const encodedResValue = btoa(res?.Response?.CompanyDetails?.nrps_required);
      const encodedResKey = btoa("nrps_required");
      localStorage.setItem(
        encodedResKey,
        encodedResValue
      );

      const encodedResValueforUpc = btoa(res?.Response?.CompanyDetails?.upc_required);
      const encodedResKeyforUpc = btoa("upc_required");
      localStorage.setItem(
        encodedResKeyforUpc,
        encodedResValueforUpc
      );

      const SpocDetails = res?.Response?.["Spoc-Details"];
      if (!SpocDetails) {
        console.log("authContext missing");
        return;
      }

      if (SpocDetails?.SessionExists) {
        messagePopup(
          "There is another session open for this user. Do you want to close that session and continue with the current login?",
          "YESNO",
          "WARNING"
        );
        return;
      }

      const CompanyDetails = res?.Response?.CompanyDetails;

      setCookie("sessionid", SpocDetails.SessionId);
      setCookie("CompanyID", SpocDetails.CompanyID);
      setCookie("SpocMail", SpocDetails.SpocMail);
      if (checked) {
        setCookie("SpocMailId", SpocDetails.SpocMail);
      } else {
        setCookie("SpocMailId", "");
      }
      localStorage.setItem("spocDetails", JSON.stringify(SpocDetails));
      dispatch({
        type: type.SET_AUTH_CONTEXT,
        payload: Object.assign(SpocDetails, { isAuth: true }),
      });
      dispatch({
        type: type.SET_COMPANY_DETAILS,
        payload: CompanyDetails,
      });
      setCaptchaData(null);
      history.push(`/dashboard`);
    } else {
      setLoginAttempts((c) => c + 1);
      showToast(
        "error",
        localizationService.toLanguageString(
          "pleaseEnterMandatoryFields", // to be updated
          `${res?.Response?.Message}`
        )
      );
    }
    setCaptchaValue("");
  };

  const onFormSubmit = (form, id = "") => {
    // debugger 
    console.log("formin", form)
    setShowLoader(null);
    console.log("forminkkkk", document.activeElement);

    if (document.activeElement.id === "login" || id === "login") {
      // if (true) {
      console.log(`inside login`);
      if (!emailId?.length || !password?.length) {
        console.log(`inside if condition`);
        showToast(
          "error",
          t('pleasefillthemandatoryfields')
        );
        return;
      } else {
        if (loginAttempts > 2) {
          if (!captchaValue) {
            showToast("error", t("pleasefillvalidcaptcha"));
            return;
          }
          if (captchaData && captchaData?.["Catcha-Content"] !== captchaValue) {
            fetchCaptcha();
            showToast("error", t("pleasefillvalidcaptcha"));
            setCaptchaValue("");
            return;
          }
        }
        // checkCookie()
        // setShowLoader({
        //   // className: ".charGrid",
        //   msg: localizationService.toLanguageString("loading", "Loading"),
        // });
        // dispatch(login(createLoginParams()));
        handleLogin();
      }
    }

    if (document.activeElement.id === "forgot") {
      console.log(`inside forgot ${emailId?.length}`);
      if (emailId?.length) {
        console.log(emailId?.length, emailId);
        setShowLoader({
          msg: localizationService.toLanguageString("loading", "Loading"),
        });
        dispatch(forgotPassword(forgotPasswordParams()));
      } else {
        showToast(
          "error",
          localizationService.toLanguageString(
            "pleaseaddvalidmailidtoproceed",
            "Please Add Valid Mail Id To Proceed.."
          )
        );
        return;
      }
    }

    if (document.activeElement.id === "next") {
      setShowLoader(null);
      // forgotPasswordValidationResponse= null;
      console.log(form.values);
      const QusAns = [];
      const Type = "VENDOR";
      const { emailId: Email, ...ques } = form?.values;
      console.log(Email);
      const entries = Object.entries(ques);
      for (const [key, value] of entries) {
        QusAns.push({ QAID: key, QAAns: value });
      }
      console.log(QusAns);
      setShowLoader({
        msg: localizationService.toLanguageString("loading", "Loading"),
      });
      dispatch(
        forgotPasswordValidation({
          Registration: {
            Email,
            QusAns,
            Type: "VENDOR",
          },
        })
      );
    }

    if (document.activeElement.id === "resetPassword") {
      setShowLoader(null);
      // forgotPasswordValidationResponse= null;
      console.log(form.values);
      const Email = form?.values?.emailId;
      const Password = form?.values?.newPassword;
      console.log(newPassword, confirmPassword);
      if (newPassword?.length && confirmPassword?.length) {
        setShowLoader({
          msg: localizationService.toLanguageString("loading", "Loading"),
        });
        dispatch(
          resetPassword({
            Registration: {
              Email,
              Password,
              Type: "VENDOR",
            },
          })
        );
      }
    }
  };

  const checkboxClicked = () => {
    setChecked((prevState) => !prevState);
    // console.log(checked);
  };


  const handleKeyDown = (event, form) => {
    console.log("keydownform", form)
    if (event.keyCode === 13) {
      event.preventDefault()
      onFormSubmit(form, "login");
    }
  };

  const showPasswordClick = (e) => {
    e.preventDefault()

    setPasswordType(() => {
      if (passwordType === "password") {
        return "text";
      } else {
        return "password";
      }
    });
  };
  const showNewPasswordClick = () => {
    setNewPasswordType(() => {
      if (newPasswordType === "password") {
        return "text";
      } else {
        return "password";
      }
    });
  };
  const showConfirmPasswordClick = () => {
    setConfirmPasswordType(() => {
      if (confirmPasswordType === "password") {
        return "text";
      } else {
        return "password";
      }
    });
  };

  const backButtonClicked = () => {
    setShowLoginForm("login");
    setPassword("");
  };
  const resetPasswordBackClicked = () => {
    setShowLoginForm("forgot");
  };
  const fetchCaptcha = async () => {
    const params = {
      _dc: Date.now(),
    };
    setShowLoader({
      className: ".captchaContainer",
      msg: t("loading")
    });
    const res = await getcaptchaAsync(params, authContext);
    setShowLoader(null);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }
    setCaptchaData(res?.root);
  };

  useEffect(() => {
    if (loginAttempts > 2) {
      fetchCaptcha();
    }
  }, [loginAttempts]);

  function getVendorIdByName(vendorName) {
    const vendor = vendorListData?.find(item => item.VendorName === vendorName);
    return vendor ? vendor.VendorId : null; // Return VendorId if found, otherwise return null
  }

  const VendorChangeHandler = (e) => {
    let vendorId = getVendorIdByName(e?.value)
    setInitialValueData({ ...intialvalueData, vendorlist: e?.value })
    setVendorIdData(vendorId)
  }

  console.log(intialvalueData,"intialvalueData")





  return (
    <>
      {showLoader && <LoadMask {...showLoader} />}
      {messageProps && <MessageBox {...messageProps} />}
      <div className="mainDiv">
        <div className="header">
          {/* <h1>Login Page</h1> */}
          <div>
            <img style={{ width: 190, position: 'relative', top: 35 }} src={NIQLogo} alt="Nielsen Logo" />
          </div>
          <div>
            <LanguageChange ifLoginPage="LoginPage" />
          </div>
          {/* <div
            style={{
              display: "flex",
              height: "100%",
              justifyContent: "end",
            }}
            className="languageInput"
          > */}
          {/* <input /> */}
          {/* <Form
              initialValues={{
                selectedLanguage: languages[0],
              }}
              render={(formRenderProps) => (
                <FormElement
                  style={{
                    display: "flex",
                    // gap: 10,
                    alignItems: "center",
                  }}
                >
                  <Field
                    style={{ height: "40px", width: "150px" }}
                    name="selectedLanguage"
                    component={FormComboBox}
                    // placeholder="Password"
                    data={languages}
                    default={languages[0]}
                    onChange={(event) => onLanguageChange(event)}
                  />
                </FormElement>
              )}
            /> */}
          {/* </div> */}
        </div>
        <div className="mainContent">
          <div className="descriptionDiv">
            <div className="labelDiv">
              Simplified People & Work Order management
            </div>
            <div className="logInImages">
              <div className="peopleMgmtImg">
                {/* <span>Img1</span> */}
                <img src={peopleMgmtImage} alt="" />
              </div>
              <div className="workOrdersImg">
                <img src={workOrdersImage} alt="" />
              </div>
            </div>
          </div>

          {showLoginForm === "login" && (
            <div className="formDiv">
              <img style={{ width: 50 }} src={NielsenLogo} alt="Nielsen Logo" />
              <div>
                <Form
                  initialValues={intialvalueData}
                  //initialValues={{ emailId: emailId?.length ? emailId : "", vendorlist : vendorListData?.length >0 ? vendorListData?.[0] : {} }}
                  onSubmit={onFormSubmit}
                  ref={updateFormRef}
                  styles={{
                    width: "100%",
                  }}
                  render={(formRenderProps) => (
                    <FormElement>
                      <div style={{ width: "100%" }}>
                        <Field
                          name="emailId"
                          component={FormInput}
                          // onKeyDown={(event) => handleKeyDown(event, formRenderProps)}
                          wrapperStyle={{ width: "100%" }}
                          // value={emailId}
                          validator={loginEmailValidator}
                          placeholder={t("emailid")}
                          onChange={(e) => onEmailChange(e, formRenderProps)}
                          setAPICall={setAPICall}
                          apiCall={apiCall}
                          // reference={emailInputRef}

                        />
                      </div>

                      <div style={{ width: "100%" }} className="vendorlistdata">


                        <Field
                          name="vendorlist"
                          component={FormComboBox}
                          textField="VendorName"
                          valueField="VendorName"
                          placeholder={localizationService.toLanguageString(
                            "Vendor",
                            "Vendor"
                          )}
                          // onKeyDown={(event)=>handleKeyDown(event, formRenderProps)}
                          data={vendorListData}
                          onChange={(e) => VendorChangeHandler(e, formRenderProps)}
                          wrapperStyle={{ width: "100%" }}
                          disabled={vendorListData.length === 1}
                          
                          


                        />
                        <div style={{ marginBottom: showsmallLoading === true ? "1px" : "22px" }}>
                          {showsmallLoading && <span><img src={miniloadigGIF} alt="Loading.." style={{ opacity: "80%", width: "100%", height: "7px" }} /></span>}
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: "20px",
                        }}
                        className={`${!password ? "pswrd" : ""}`}
                      >
                        <Field
                          name="password"
                          type={passwordType}
                          onClick={showPasswordClick}
                          onKeyDown={(event) => handleKeyDown(event, formRenderProps)}
                          component={LoginPasswordInput}
                          placeholder={t("Password")}
                          // validator={inputValidator}
                          // style={{border:'5px solid red'}}
                          onChange={(e) => onPasswordChange(e, formRenderProps)}
                        />
                      </div>
                      {captchaData && (
                        <div className="k-form-field captchaContainer">
                          <div>Captcha:*</div>
                          <div style={{ display: "flex", gap: 5 }}>
                            <div
                              style={{
                                height: 34,
                                overflow: "hidden",
                                flex: 1,
                              }}
                            >
                              <img
                                style={{ width: "100%" }}
                                src={
                                  "data:image/png;base64," +
                                  captchaData?.BinaryContent
                                }
                                alt="captcha"
                              />
                            </div>
                            <Button icon="refresh" onClick={fetchCaptcha} />
                            <div style={{ flex: 1.5 }}>
                              <Input
                                type="text"
                                placeholder="Captcha"
                                value={captchaValue}
                                onChange={(e) => {
                                  setCaptchaValue(e.value);
                                }}
                                onKeyDown={(event) => handleKeyDown(event, formRenderProps)}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div
                        style={{
                          // display:'flex', gap: 150
                          marginTop: "10px",
                        }}
                      >
                        <Checkbox
                          disabled={false}
                          value=""
                          icon="k-icon k-i-search"
                          className="k-tertiary"
                          data-key="export"
                          label={t("rememberme")}
                          onClick={checkboxClicked}
                        // checked={props?.dataItem?.SelectType}
                        >
                          {/* {localizationService.toLanguageString("export", "Export")} */}
                        </Checkbox>
                        {/* <label>Remenber Me</label> */}
                      </div>

                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "15px",
                        }}
                      >
                        <Button
                          style={{
                            width: "100%",
                            height: "40px",
                          }}
                          type="submit"
                          primary={true}
                          id="login"
                          disabled={intialvalueData?.vendorlist === "" || intialvalueData?.vendorlist === null}
                        >
                          {t("LOGIN")}
                        </Button>
                      </div>
                      <div>
                        <Button
                          id="forgot"
                          type="submit"
                          style={{
                            width: "100%",
                            height: "40px",
                          }}
                          className="k-secondary"
                        // onClick={forgetPasswordClicked}
                        >
                          {t("forgotpassword")}
                        </Button>
                      </div>
                    </FormElement>
                  )}
                />
              </div>
            </div>
          )}
          {showLoginForm === "forgot" && emailId?.length > 0 && (
            <div className="formDiv">
              <div
                style={{
                  display: "flex",
                  gap: 13,
                }}
              >
                <img style={{ width: 50 }} src={NielsenLogo} alt="Nielsen Logo" />
                <h3>{t('forgotpassword')}</h3>
              </div>

              <div>
                <Form
                  initialValues={{ emailId: emailId?.length ? emailId : "" }}
                  onSubmitClick={onFormSubmit}
                  styles={{
                    width: "100%",
                  }}
                  render={(formRenderProps) => (
                    <FormElement>
                      <div
                        style={{
                          marginBottom: "30px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            marginTop: "10px",
                          }}
                        >
                          <Field
                            name="emailId"
                            label={t("emailid")}
                            required={true}
                            disabled={true}
                            component={FormInput}
                            // component={FormComboBox}
                            wrapperStyle={{ width: "100%" }}
                            // validationMessage='This field should be an e-mail address in the format "user@example.com"'
                            // validator={()=>emailValidator(emailValidatorText)}
                            validator={loginEmailValidator}
                            placeholder={t("emailid")}
                          // data={emailId}
                          // initialValues={emailId}
                          />
                        </div>
                        <div
                          style={{
                            marginTop: "20px",
                            // height: '20px'
                          }}
                        >
                          <span>{t('securityquestions')}*</span>
                        </div>
                        <div style={{ fontSize: "12px" }}>
                          {forgotPasswordQuestion.map((question) => {
                            const Question_Id = question?.Question_Id
                            if (question.Question_Id === "1")
                              question.Question_Description = t('QUESTION_CAR_LBL');
                            if (question.Question_Id === "2")
                              question.Question_Description = t('QUESTION_RESTAURANT_LBL');
                            if (question.Question_Id === "3")
                              question.Question_Description = t('QUESTION_PET_LBL');
                            if (question.Question_Id === "4")
                              question.Question_Description = t('QUESTION_JOB_LBL');
                            if (question.Question_Id === "5")
                              question.Question_Description = t('QUESTION_SCHOOL_LBL');
                            return (
                              <div key={Question_Id}>
                                <Field
                                  // name="firstCarBrand"
                                  name={Question_Id}
                                  required={true}
                                  // label={`What Is The Make Of Your First Car?`}
                                  label={question?.Question_Description}
                                  // onClick={showPasswordClick}
                                  // validationMessage=''
                                  component={FormInput}
                                  placeholder={t('provideyouranswer')}
                                  validator={inputValidator}
                                />
                              </div>
                            );
                          })}

                          {/* {forgotPasswordQuestion.map((question) => {
                            const Question_Id = question?.Question_Id
                            return (
                              <div key={Question_Id}>
                                <Field
                                  // name="firstCarBrand"
                                  name={Question_Id}
                                  required={true}
                                  // label={`What Is The Make Of Your First Car?`}
                                  label={question?.Question_Description}
                                  // onClick={showPasswordClick}
                                  // validationMessage=''
                                  component={FormInput}
                                  placeholder={t('provideyouranswer')}
                                  validator={inputValidator}
                                />
                              </div>
                            );
                          })} */}

                          {/* <div>
                            <Field
                              name="firstPetName"
                              required={true}
                              label={`What is your first pet's name?`}
                              // onClick={showPasswordClick}
                              // validationMessage=''
                              component={FormInput}
                              placeholder={localizationService.toLanguageString(
                                "provideYourAnswer",
                                `Provide Your Answer`
                              )}
                              validator={inputValidator}
                            />
                          </div>
                          <div>
                            <Field
                              name="firstJobTitle"
                              required={true}
                              label={`What Was The First Title Of Your First Job?`}
                              // onClick={showPasswordClick}
                              // validationMessage=''
                              component={FormInput}
                              placeholder={localizationService.toLanguageString(
                                "provideYourAnswer",
                                `Provide Your Answer`
                              )}
                              validator={inputValidator}
                            />
                          </div> */}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          // gap: 100
                        }}
                      >
                        <div
                          className="k-secondary"
                          style={{
                            // marginTop: `40px`,
                            // height:'100%',
                            width: `100%`,
                            // backgroundColor:'red',
                          }}
                        >
                          <Button
                            style={{
                              // width: "100%",
                              height: "40px",
                            }}
                            icon="k-icon k-i-arrow-left"
                            className="k-secondary"
                            onClick={backButtonClicked}
                          >
                            {localizationService.toLanguageString(
                              "back",
                              `Back`
                            )}
                          </Button>
                        </div>
                        <div
                          style={{
                            // marginBottom: "10px",
                            backgroundColor: "red",
                            display: "flex",
                            justifyContent: "end",
                            // alignItems: 'end'
                          }}
                        >
                          <Button
                            className="k-primary"
                            style={{
                              // width: "100%",
                              height: "40px",
                            }}
                            type={"submit"}
                            id="next"
                          // primary={true}
                          // style={{ width: "25%" }}
                          >
                            {localizationService.toLanguageString(
                              "next",
                              "Next"
                            )}
                          </Button>
                        </div>
                      </div>
                    </FormElement>
                  )}
                />
              </div>
            </div>
          )}
          {showLoginForm === "changePassword" && (
            <div className="formDiv">
              <div
                style={{
                  display: "flex",
                  gap: 13,
                }}
              >
                <img style={{ width: 50 }} src={NielsenLogo} alt="Nielsen Logo" />
                <h3>{t('forgotpassword')}</h3>
              </div>

              <div>
                <Form
                  initialValues={{ emailId: emailId?.length ? emailId : "" }}
                  onSubmitClick={onFormSubmit}
                  styles={{
                    width: "100%",
                  }}
                  render={(formRenderProps) => (
                    <FormElement>
                      <div
                        style={{
                          marginBottom: "100px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            marginTop: "30px",
                          }}
                        >
                          <span>{`${t('newpassword')}`}</span>
                          <Field
                            name="newPassword"
                            type={newPasswordType}
                            onClick={showNewPasswordClick}
                            // validationMessage=''
                            component={LoginPasswordInput}
                            placeholder={t('newpassword')}
                            // label="New Password"
                            required={true}
                            validator={forgotPasswordValidator}
                            onChange={(e) =>
                              newPasswordChange(e, formRenderProps)
                            }
                          // onChange={(e) => {setNewPassword(e.value); console.log(newPassword)}}
                          />
                        </div>
                        <div
                          style={{
                            width: "100%",
                            marginTop: "30px",
                          }}
                        >
                          <span>{`${t('confirmpassword')}`}</span>
                          <Field
                            name="confirmPassword"
                            type={confirmPasswordType}
                            onClick={showConfirmPasswordClick}
                            component={LoginPasswordInput}
                            placeholder={t('confirmpassword')}
                            required={true}
                            // label="Confirm Password"
                            validator={confirmPasswordValidator}
                            onChange={(e) =>
                              confirmPasswordChange(e, formRenderProps)
                            }
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          className="k-secondary"
                          style={{
                            width: `100%`,
                          }}
                        >
                          <Button
                            style={{
                              height: "40px",
                            }}
                            icon="k-icon k-i-arrow-left"
                            className="k-secondary"
                            onClick={resetPasswordBackClicked}
                          >
                            {localizationService.toLanguageString(
                              "back",
                              `Back`
                            )}
                          </Button>
                        </div>
                        <div
                          style={{
                            backgroundColor: "red",
                            display: "flex",
                            justifyContent: "end",
                          }}
                        >
                          <Button
                            className="k-primary"
                            style={{
                              height: "40px",
                            }}
                            type={"submit"}
                            id="resetPassword"
                          >
                            {localizationService.toLanguageString(
                              "submit",
                              "Submit"
                            )}
                          </Button>
                        </div>
                      </div>
                    </FormElement>
                  )}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <MainFooter />
    </>
  );
};

export default Login;
