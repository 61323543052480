import React from 'react';
import './style.css';
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { FormComboBox, FormComboBoxWithDefaultValue, FormMultiSelect } from '../../../form/form-component';
import Button from '@progress/kendo-react-buttons/dist/npm/Button';
import { useLocalization } from '@progress/kendo-react-intl/dist/npm/hooks';
import { inputValidator } from '../../../form/validator';
import { useDispatch, useSelector } from 'react-redux';
import { byCommonFiltersActiveTab, getCommonFiltersParams, getNRPSStoreStoresInfo, postNrpsReportAuditorClick, postNrpsReportKpiClick, postNrpsReportPlanClick, postNrpsReportProgressClick, setDefaultYearAndWeek, sidebarOpenCloseAction } from '../../../../store/actions/reports';
import { filterBy } from '@progress/kendo-react-data-tools';


const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const startDate = new Date(currentDate.getFullYear(), 0, 1);
const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
const currentWeek = Math.ceil(days / 7);

const CommonFilters = ({ callbackTabFilters, activeIndex }) => {
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const tabFiltersData = useSelector((store) => store?.reports?.nrpsReportFilters);
  const commonFiltersParams = useSelector((store) => store?.reports?.nrpsCommonFiltersParams);

  const localizationService = useLocalization();
  const dispatch = useDispatch();

  const [selectedWeekData, setSelectedWeekData] = React.useState([]);
  const [selectedStoreIds, setSelectedStoreIds] = React.useState([]);
  const [selectedRegionData, setSelectedRegionData] = React.useState([]);
  const [storeNameData, setStoreNameData] = React.useState([]);
  const [storeCityData, setStoreCityData] = React.useState([]);
  const [storeProvinceData, setStoreProvinceData] = React.useState([]);
  const [tabFiltersRes, setTabFiltersRes] = React.useState([]);
  const [weekRespectValues, setWeekRespectValues] = React.useState([]);
  const [weekRespectRegions, setWeekRespectRegions] = React.useState([]);
  const [weekRespectStoreIds, setWeekRespectStoreIds] = React.useState([]);
  const [weekRespectStoreNames, setWeekRespectStoreNames] = React.useState([]);
  const [weekRespectCities, setWeekRespectCities] = React.useState([]);
  const [weekRespectProvinces, setWeekRespectProvinces] = React.useState([]);
  const [filterCity, setFilterCity] = React.useState([]);
  const [defaultYear, setDefaultYear] = React.useState("");
  const [defaultWeek, setDefaultWeek] = React.useState(currentWeek);
  console.log("defaultYear", typeof defaultYear)



  React.useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    const days = Math.floor(
      (currentDate - startDate) / (24 * 60 * 60 * 1000)
    );
    const currentWeek = Math.ceil(days / 7);
    const searchObject = tabFiltersData?.find(
      (max) => max.year == currentYear
    );
    const maxYear = searchObject?.year;
    const maxWeek = searchObject?.nrpsweekFilterList.find(
      (max) => max.week == currentWeek
    );
    dispatch(setDefaultYearAndWeek({ year: maxYear, week: maxWeek?.week }));
  }, [tabFiltersData]);

  React.useEffect(() => {
    if (tabFiltersData !== undefined) {
      setTimeout(() => {
        changeYear({ value: tabFiltersData?.[0]?.year });
        setDefaultYear(tabFiltersData?.[0]?.year)
      }, 3000)
    }

  }, [tabFiltersData]);

  const changeYear = (event) => {
    dispatch(getCommonFiltersParams({ ...commonFiltersParams, year: event.value }));
    if (event.value) {
      const temp = tabFiltersData?.find((obj) => {
        return obj.year === event.value
      });
      const sortedWeeks = temp?.nrpsweekFilterList.sort((a, b) => {
        return b.week - a.week
      })
      setSelectedWeekData(sortedWeeks);
    }
  };

  const changeWeek = (event) => {
    changeYear(tabFiltersData?.[0]?.year)
    dispatch(getCommonFiltersParams({ ...commonFiltersParams, week: event?.value?.map((item) => item.week) }));
    const newStoreFilter = [];
    event.value.forEach((item) => {
      newStoreFilter.push(...item?.storeFilter);
    });

    const uniqueFiltersData = [];
    for (const item of newStoreFilter) {
      const duplicate = uniqueFiltersData.find(
        (obj) => obj.smsId === item.smsId
      );
      if (!duplicate) {
        uniqueFiltersData.push(item);
      }
    }
    setWeekRespectValues(uniqueFiltersData);

    const uniqueStoreIds = [];
    for (const item of newStoreFilter) {
      const duplicate = uniqueStoreIds.find(
        (obj) => obj.smsId === item.smsId
      );
      if (!duplicate) {
        uniqueStoreIds.push(item);
      }
    }
    const sortedStoreIds = uniqueStoreIds?.filter((n) => n.smsId).sort((a, b) => {
      let fa = a?.smsId?.toLowerCase(),
        fb = b?.smsId?.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    setSelectedStoreIds(sortedStoreIds);
    setWeekRespectStoreIds(sortedStoreIds);

    const uniqueStoreName = [];
    for (const item of newStoreFilter) {
      const duplicate = uniqueStoreName.find(
        (obj) => obj.storeName === item.storeName
      );
      if (!duplicate) {
        uniqueStoreName.push(item);
      }
    }
    const sortedStoreName = uniqueStoreName?.filter((n) => n.storeName).sort((a, b) => {
      let fa = a?.storeName?.toLowerCase(),
        fb = b?.storeName?.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    setStoreNameData(sortedStoreName);
    setWeekRespectStoreNames(sortedStoreName);

    const uniqueCity = [];
    for (const item of newStoreFilter) {
      const duplicate = uniqueCity.find(
        (obj) => obj.city === item.city
      );
      if (!duplicate) {
        uniqueCity.push(item);
      }
    }
    const sortedCity = uniqueCity?.filter((n) => n.city).sort((a, b) => {
      let fa = a?.city?.toLowerCase(),
        fb = b?.city?.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    setStoreCityData(sortedCity);
    setWeekRespectCities(sortedCity);

    const uniqueProvince = [];
    for (const item of newStoreFilter) {
      const duplicate = uniqueProvince.find(
        (obj) => obj.province === item.province
      );
      if (!duplicate) {
        uniqueProvince.push(item);
      }
    }
    const sortedProvince = uniqueProvince?.filter((n) => n.province).sort((a, b) => {
      let fa = a?.province?.toLowerCase(),
        fb = b?.province?.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    setStoreProvinceData(sortedProvince);
    setWeekRespectProvinces(sortedProvince);

    const uniqueRegion = [];
    for (const item of newStoreFilter) {
      const duplicate = uniqueRegion.find(
        (obj) => obj.region === item.region
      );
      if (!duplicate) {
        uniqueRegion.push(item);
      }
    }
    const sortedRegion = uniqueRegion?.filter((n) => n.region).sort((a, b) => {
      let fa = a?.region?.toLowerCase(),
        fb = b?.region?.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    console.log("sortedRegion", sortedRegion)
    setSelectedRegionData(sortedRegion);
    setWeekRespectRegions(sortedRegion);
    setTabFiltersRes(event.value);
  };
  callbackTabFilters(tabFiltersRes);

  const changeStoreId = (event) => {
    if (event.value.length > 0) {
      dispatch(getCommonFiltersParams({ ...commonFiltersParams, storeId: event?.value?.map((item) => item.smsId).toString() }));

      const temp = [];
      event.value.forEach((item) => {
        temp.push(item);
      });

      const truthyRegion = weekRespectValues.filter(function (el) {
        return el.region !== null
      }
      );
      const uniqueRegion = [];
      for (const item of truthyRegion) {
        const duplicate = uniqueRegion.find(
          (obj) => obj.region === item.region
        );
        if (!duplicate) {
          uniqueRegion.push(item);
        }
      }
      const uniqueTruthyRegion = uniqueRegion?.filter((n) => n.region).sort((a, b) => {
        let fa = a?.region?.toLowerCase(),
          fb = b?.region?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      const truthyCity = weekRespectValues.filter(function (el) {
        return el.city !== null
      }
      );
      const uniqueCity = [];
      for (const item of truthyCity) {
        const duplicate = uniqueCity.find(
          (obj) => obj.city === item.city
        );
        if (!duplicate) {
          uniqueCity.push(item);
        }
      }
      const uniqueTruthyCity = uniqueCity?.filter((n) => n.city).sort((a, b) => {
        let fa = a?.city?.toLowerCase(),
          fb = b?.city?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      const truthyProvince = weekRespectValues.filter(function (el) {
        return el.province !== null
      }
      );
      const uniqueProvince = [];
      for (const item of truthyProvince) {
        const duplicate = uniqueProvince.find(
          (obj) => obj.province === item.province
        );
        if (!duplicate) {
          uniqueProvince.push(item);
        }
      }
      const uniqueTruthyProvince = uniqueProvince?.filter((n) => n.province).sort((a, b) => {
        let fa = a?.province?.toLowerCase(),
          fb = b?.province?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      const truthyStoreName = weekRespectValues.filter(function (el) {
        return el.storeName !== null
      }
      );
      const uniqueStoreName = [];
      for (const item of truthyStoreName) {
        const duplicate = uniqueStoreName.find(
          (obj) => obj.storeName === item.storeName
        );
        if (!duplicate) {
          uniqueStoreName.push(item);
        }
      }
      const uniqueTruthyStoreName = uniqueStoreName?.filter((n) => n.storeName).sort((a, b) => {
        let fa = a?.storeName?.toLowerCase(),
          fb = b?.storeName?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      setStoreNameData(uniqueTruthyStoreName);
      setStoreCityData(uniqueTruthyCity);
      setStoreProvinceData(uniqueTruthyProvince);
      setSelectedRegionData(uniqueTruthyRegion);
    } else {
      setStoreNameData(weekRespectStoreNames);
      setStoreCityData(weekRespectCities);
      setStoreProvinceData(weekRespectProvinces);
      setSelectedRegionData(weekRespectRegions);
    }
  };

  const changeRegion = (event) => {
    if (event.value.length > 0) {
      dispatch(getCommonFiltersParams({ ...commonFiltersParams, region: event?.value?.map((item) => item.region).toString() }));

      const temp = [];
      event.value.forEach((item) => {
        temp.push(item);
      });

      // const truthyRegion = temp.filter(function (el)
      const truthyRegion = weekRespectValues.filter(function (el) {
        return el.region !== null
      }
      );
      const uniqueRegion = [];
      for (const item of truthyRegion) {
        const duplicate = uniqueRegion.find(
          (obj) => obj.region === item.region
        );
        if (!duplicate) {
          uniqueRegion.push(item);
        }
      }
      const uniqueTruthyRegion = uniqueRegion?.filter((n) => n.region).sort((a, b) => {
        let fa = a?.region?.toLowerCase(),
          fb = b?.region?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      const truthyCity = weekRespectValues.filter(function (el) {
        return el.city !== null
      }
      );
      const uniqueCity = [];
      for (const item of truthyCity) {
        const duplicate = uniqueCity.find(
          (obj) => obj.city === item.city
        );
        if (!duplicate) {
          uniqueCity.push(item);
        }
      }
      const uniqueTruthyCity = uniqueCity?.filter((n) => n.city).sort((a, b) => {
        let fa = a?.city?.toLowerCase(),
          fb = b?.city?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      const truthyProvince = weekRespectValues.filter(function (el) {
        return el.province !== null
      }
      );
      const uniqueProvince = [];
      for (const item of truthyProvince) {
        const duplicate = uniqueProvince.find(
          (obj) => obj.province === item.province
        );
        if (!duplicate) {
          uniqueProvince.push(item);
        }
      }
      const uniqueTruthyProvince = uniqueProvince?.filter((n) => n.province).sort((a, b) => {
        let fa = a?.province?.toLowerCase(),
          fb = b?.province?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      const truthyStoreName = weekRespectValues.filter(function (el) {
        return el.storeName !== null
      }
      );
      const uniqueStoreName = [];
      for (const item of truthyStoreName) {
        const duplicate = uniqueStoreName.find(
          (obj) => obj.storeName === item.storeName
        );
        if (!duplicate) {
          uniqueStoreName.push(item);
        }
      }
      const uniqueTruthyStoreName = uniqueStoreName?.filter((n) => n.storeName).sort((a, b) => {
        let fa = a?.storeName?.toLowerCase(),
          fb = b?.storeName?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      const truthySmsId = weekRespectValues.filter(function (el) {
        return el.smsId !== null
      }
      );
      const uniqueSmsId = [];
      for (const item of truthySmsId) {
        const duplicate = uniqueSmsId.find(
          (obj) => obj.smsId === item.smsId
        );
        if (!duplicate) {
          uniqueSmsId.push(item);
        }
      }
      const uniqueTruthySmsId = uniqueSmsId?.filter((n) => n.smsId).sort((a, b) => {
        let fa = a?.smsId?.toLowerCase(),
          fb = b?.smsId?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      // setStoreNameData(temp); 
      setStoreNameData(uniqueTruthyStoreName);
      setStoreCityData(uniqueTruthyCity);
      setStoreProvinceData(uniqueTruthyProvince);
      setSelectedStoreIds(uniqueTruthySmsId);
    } else {
      setSelectedStoreIds(weekRespectStoreIds);
      setStoreCityData(weekRespectCities);
      setStoreProvinceData(weekRespectProvinces);
      setStoreNameData(weekRespectStoreNames);
    }
  };

  const changeStoreName = (event) => {
    if (event.value.length > 0) {
      dispatch(getCommonFiltersParams({ ...commonFiltersParams, storeName: event?.value?.map((item) => item.storeName).toString() }));

      const temp = [];
      event.value.forEach((item) => {
        temp.push(item);
      });

      const truthyRegion = weekRespectValues.filter(function (el) {
        return el.region !== null
      }
      );
      const uniqueRegion = [];
      for (const item of truthyRegion) {
        const duplicate = uniqueRegion.find(
          (obj) => obj.region === item.region
        );
        if (!duplicate) {
          uniqueRegion.push(item);
        }
      }
      const uniqueTruthyRegion = uniqueRegion?.filter((n) => n.region).sort((a, b) => {
        let fa = a?.region?.toLowerCase(),
          fb = b?.region?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      const truthyCity = weekRespectValues.filter(function (el) {
        return el.city !== null
      }
      );
      const uniqueCity = [];
      for (const item of truthyCity) {
        const duplicate = uniqueCity.find(
          (obj) => obj.city === item.city
        );
        if (!duplicate) {
          uniqueCity.push(item);
        }
      }
      const uniqueTruthyCity = uniqueCity?.filter((n) => n.city).sort((a, b) => {
        let fa = a?.city?.toLowerCase(),
          fb = b?.city?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      const truthyProvince = weekRespectValues.filter(function (el) {
        return el.province !== null
      }
      );
      const uniqueProvince = [];
      for (const item of truthyProvince) {
        const duplicate = uniqueProvince.find(
          (obj) => obj.province === item.province
        );
        if (!duplicate) {
          uniqueProvince.push(item);
        }
      }
      const uniqueTruthyProvince = uniqueProvince?.filter((n) => n.province).sort((a, b) => {
        let fa = a?.province?.toLowerCase(),
          fb = b?.province?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      const truthyStoreName = weekRespectValues.filter(function (el) {
        return el.storeName !== null
      }
      );
      const uniqueStoreName = [];
      for (const item of truthyStoreName) {
        const duplicate = uniqueStoreName.find(
          (obj) => obj.storeName === item.storeName
        );
        if (!duplicate) {
          uniqueStoreName.push(item);
        }
      }
      const uniqueTruthyStoreName = uniqueStoreName?.filter((n) => n.storeName).sort((a, b) => {
        let fa = a?.storeName?.toLowerCase(),
          fb = b?.storeName?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      const truthySmsId = weekRespectValues.filter(function (el) {
        return el.smsId !== null
      }
      );
      const uniqueSmsId = [];
      for (const item of truthySmsId) {
        const duplicate = uniqueSmsId.find(
          (obj) => obj.smsId === item.smsId
        );
        if (!duplicate) {
          uniqueSmsId.push(item);
        }
      }
      const uniqueTruthySmsId = uniqueSmsId?.filter((n) => n.smsId).sort((a, b) => {
        let fa = a?.smsId?.toLowerCase(),
          fb = b?.smsId?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      // setStoreNameData(temp); 
      setSelectedStoreIds(uniqueTruthySmsId);
      setStoreCityData(uniqueTruthyCity);
      setStoreProvinceData(uniqueTruthyProvince);
      setSelectedRegionData(uniqueTruthyRegion);
    } else {
      setSelectedStoreIds(weekRespectStoreIds);
      setStoreCityData(weekRespectCities);
      setStoreProvinceData(weekRespectProvinces);
      setSelectedRegionData(weekRespectRegions);
    }
  };

  const changeCity = (event) => {
    if (event.value.length > 0) {
      dispatch(getCommonFiltersParams({ ...commonFiltersParams, city: event?.value?.map((item) => item.city).toString() }));

      const temp = [];
      event.value.forEach((item) => {
        temp.push(item);
      });

      // const truthyRegion = temp.filter(function (el)
      const truthyRegion = weekRespectValues.filter(function (el) {
        return el.region !== null
      }
      );
      const uniqueRegion = [];
      for (const item of truthyRegion) {
        const duplicate = uniqueRegion.find(
          (obj) => obj.region === item.region
        );
        if (!duplicate) {
          uniqueRegion.push(item);
        }
      }
      const uniqueTruthyRegion = uniqueRegion?.filter((n) => n.region).sort((a, b) => {
        let fa = a?.region?.toLowerCase(),
          fb = b?.region?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      const truthyCity = weekRespectValues.filter(function (el) {
        return el.city !== null
      }
      );
      const uniqueCity = [];
      for (const item of truthyCity) {
        const duplicate = uniqueCity.find(
          (obj) => obj.city === item.city
        );
        if (!duplicate) {
          uniqueCity.push(item);
        }
      }
      const uniqueTruthyCity = uniqueCity?.filter((n) => n.city).sort((a, b) => {
        let fa = a?.city?.toLowerCase(),
          fb = b?.city?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      setFilterCity(uniqueTruthyCity)

      const truthyProvince = weekRespectValues.filter(function (el) {
        return el.province !== null
      }
      );
      const uniqueProvince = [];
      for (const item of truthyProvince) {
        const duplicate = uniqueProvince.find(
          (obj) => obj.province === item.province
        );
        if (!duplicate) {
          uniqueProvince.push(item);
        }
      }
      const uniqueTruthyProvince = uniqueProvince?.filter((n) => n.province).sort((a, b) => {
        let fa = a?.province?.toLowerCase(),
          fb = b?.province?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      const truthyStoreName = weekRespectValues.filter(function (el) {
        return el.storeName !== null
      }
      );
      const uniqueStoreName = [];
      for (const item of truthyStoreName) {
        const duplicate = uniqueStoreName.find(
          (obj) => obj.storeName === item.storeName
        );
        if (!duplicate) {
          uniqueStoreName.push(item);
        }
      }
      const uniqueTruthyStoreName = uniqueStoreName?.filter((n) => n.storeName).sort((a, b) => {
        let fa = a?.storeName?.toLowerCase(),
          fb = b?.storeName?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      const truthySmsId = weekRespectValues.filter(function (el) {
        return el.smsId !== null
      }
      );
      const uniqueSmsId = [];
      for (const item of truthySmsId) {
        const duplicate = uniqueSmsId.find(
          (obj) => obj.smsId === item.smsId
        );
        if (!duplicate) {
          uniqueSmsId.push(item);
        }
      }
      const uniqueTruthySmsId = uniqueSmsId?.filter((n) => n.smsId).sort((a, b) => {
        let fa = a?.smsId?.toLowerCase(),
          fb = b?.smsId?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      // setStoreNameData(temp); 
      setStoreNameData(uniqueTruthyStoreName);
      setSelectedStoreIds(uniqueTruthySmsId);
      setStoreProvinceData(uniqueTruthyProvince);
      setSelectedRegionData(uniqueTruthyRegion);

    } else {
      setSelectedStoreIds(weekRespectStoreIds);
      setStoreNameData(weekRespectStoreNames);
      setStoreProvinceData(weekRespectProvinces);
      setSelectedRegionData(weekRespectRegions);
    }
  };

  const changeProvince = (event) => {
    if (event.value.length > 0) {
      dispatch(getCommonFiltersParams({ ...commonFiltersParams, province: event?.value?.map((item) => item.province).toString() }));

      const temp = [];
      event.value.forEach((item) => {
        temp.push(item);
      });

      // const truthyRegion = temp.filter(function (el)
      const truthyRegion = weekRespectValues.filter(function (el) {
        return el.region !== null
      }
      );
      const uniqueRegion = [];
      for (const item of truthyRegion) {
        const duplicate = uniqueRegion.find(
          (obj) => obj.region === item.region
        );
        if (!duplicate) {
          uniqueRegion.push(item);
        }
      }
      const uniqueTruthyRegion = uniqueRegion?.filter((n) => n.region).sort((a, b) => {
        let fa = a?.region?.toLowerCase(),
          fb = b?.region?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      const truthyCity = weekRespectValues.filter(function (el) {
        return el.city !== null
      }
      );
      const uniqueCity = [];
      for (const item of truthyCity) {
        const duplicate = uniqueCity.find(
          (obj) => obj.city === item.city
        );
        if (!duplicate) {
          uniqueCity.push(item);
        }
      }
      const uniqueTruthyCity = uniqueCity?.filter((n) => n.city).sort((a, b) => {
        let fa = a?.city?.toLowerCase(),
          fb = b?.city?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      const truthyProvince = weekRespectValues.filter(function (el) {
        return el.province !== null
      }
      );
      const uniqueProvince = [];
      for (const item of truthyProvince) {
        const duplicate = uniqueProvince.find(
          (obj) => obj.province === item.province
        );
        if (!duplicate) {
          uniqueProvince.push(item);
        }
      }
      const uniqueTruthyProvince = uniqueProvince?.filter((n) => n.province).sort((a, b) => {
        let fa = a?.province?.toLowerCase(),
          fb = b?.province?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      const truthyStoreName = weekRespectValues.filter(function (el) {
        return el.storeName !== null
      }
      );
      const uniqueStoreName = [];
      for (const item of truthyStoreName) {
        const duplicate = uniqueStoreName.find(
          (obj) => obj.storeName === item.storeName
        );
        if (!duplicate) {
          uniqueStoreName.push(item);
        }
      }
      const uniqueTruthyStoreName = uniqueStoreName?.filter((n) => n.storeName).sort((a, b) => {
        let fa = a?.storeName?.toLowerCase(),
          fb = b?.storeName?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      const truthySmsId = weekRespectValues.filter(function (el) {
        return el.smsId !== null
      }
      );
      const uniqueSmsId = [];
      for (const item of truthySmsId) {
        const duplicate = uniqueSmsId.find(
          (obj) => obj.smsId === item.smsId
        );
        if (!duplicate) {
          uniqueSmsId.push(item);
        }
      }
      const uniqueTruthySmsId = uniqueSmsId?.filter((n) => n.smsId).sort((a, b) => {
        let fa = a?.smsId?.toLowerCase(),
          fb = b?.smsId?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      // setStoreNameData(temp); 
      setStoreNameData(uniqueTruthyStoreName);
      setStoreCityData(uniqueTruthyCity);
      setSelectedStoreIds(uniqueTruthySmsId);
      setSelectedRegionData(uniqueTruthyRegion);
    } else {
      setSelectedStoreIds(weekRespectStoreIds);
      setStoreNameData(weekRespectStoreNames);
      setStoreCityData(weekRespectCities);
      setSelectedRegionData(weekRespectRegions);
    }
  };

  const onSearchClick = (form) => {
    if ((form.values?.year || defaultYear) && form.values?.week?.map((item) => item.week).toString()) {
      if (authContext) {
        const params = {
          "vendorId": [parseInt(authContext.CompanyID)],
          "country": authContext.CountryCode,
          "year": (form.values?.year) ? (form.values?.year) : defaultYear,
          "week": (form.values?.week) ? (form.values?.week.map((item) => item.week)) : "",
          "storeId": (form.values?.storeId) ? (form.values?.storeId.map((item) => item.smsId).toString()) : "",
          "storeName": (form.values?.storeName) ? (form.values?.storeName.map((item) => item.storeName).toString()) : "",
          "storeType": (form.values?.storeType) ? (form.values?.storeType) : "",
          "city": (form.values?.city) ? (form.values?.city.map((item) => item.city).toString()) : "",
          "province": (form.values?.province) ? (form.values?.province.map((item) => item.province).toString()) : "",
          "region": (form.values?.region) ? (form.values?.region.map((item) => item.region).toString()) : "",
          "auditorId": "",
          "banner": "",
          "collectionType": "",
          "limitDate": "",
          "reportType": "plan",
          "status": "",
          "product": "",
          "studyName":"",
          "startRange": 0,
          "endRange": 0,
          "pageSize": 200,
          "pageNo": 0
        };
        dispatch(getCommonFiltersParams(params));

        switch (activeIndex) {
          case 1:
            params.reportType = 'plan'
            console.log("planrrrr", params, JSON.stringify(params))
            dispatch(postNrpsReportPlanClick(params, authContext));
            dispatch(getNRPSStoreStoresInfo(params, authContext))
            dispatch(byCommonFiltersActiveTab(1));
            break;
          case 2:
            params.reportType = 'progress'
            dispatch(postNrpsReportProgressClick(params, authContext));
            dispatch(getNRPSStoreStoresInfo(params, authContext))
            dispatch(byCommonFiltersActiveTab(2));
            break;
          case 3:
            params.reportType = 'kpi'
            dispatch(postNrpsReportKpiClick(params, authContext));
            dispatch(getNRPSStoreStoresInfo(params, authContext))
            dispatch(byCommonFiltersActiveTab(3));
            break;
          case 4:
            params.reportType = 'auditor'
            dispatch(postNrpsReportAuditorClick(params, authContext));
            dispatch(getNRPSStoreStoresInfo(params, authContext))
            dispatch(byCommonFiltersActiveTab(4));
            break;
          default:
            break;
        }
      }
      dispatch(sidebarOpenCloseAction(false))

    }

  }

  return (
    <React.Fragment>
      <div style={{ display: "flex", flexDirection: 'column', padding: "0px 5px" }}>
        <Form
          onSubmitClick={onSearchClick}
          render={(formRenderProps) => (
            <React.Fragment>
              <FormElement>
                <div >
                  <Field
                    name={"year"}
                    // component={FormComboBoxWithDefaultValue}
                    component={FormComboBox}
                    // textField="year"
                    // valueField="year"
                    required={true}
                    // validator={inputValidator}
                    label="YEAR"
                    placeholder="Year"
                    // defaultValue={"2023"}
                    value={defaultYear}
                    data={tabFiltersData?.sort((a, b) => { return b.year - a.year }).map((item) => {
                      return item?.year
                    })}
                    // data={selectedYearData}                       
                    onChange={changeYear}
                    defaultValue={defaultYear}
                  // defaultValue={tabFiltersData?.[0]?.year}
                  />
                  <Field
                    name={"week"}
                    component={FormMultiSelect}
                    textField="week"
                    valueField="week"
                    required={true}
                    validator={inputValidator}
                    label="WEEK"
                    placeholder="Week"
                    // defaultValue={"11"}
                    // value={selectedWeekData}                                                                 
                    data={selectedWeekData}
                    onChange={changeWeek}
                    // defaultValue={selectedWeekData[0]}
                    defaultValue={selectedWeekData.find(el => el.week === defaultWeek)}
                  />
                  <Field
                    name="storeId"
                    component={FormMultiSelect}
                    textField="smsId"
                    valueField="smsId"
                    label="STORE ID"
                    placeholder="Store Id"
                    data={selectedStoreIds}
                    onChange={changeStoreId}
                  />
                  <Field
                    name="storeName"
                    component={FormMultiSelect}
                    textField="storeName"
                    valueField="storeName"
                    label="STORE NAME"
                    placeholder="Store Name"
                    // value={currentCountry}                        
                    data={storeNameData}
                    onChange={changeStoreName}
                  />
                  <Field
                    name="region"
                    component={FormMultiSelect}
                    textField="region"
                    valueField="region"
                    label="REGION"
                    placeholder="Region"
                    data={selectedRegionData}
                    // data={selectedRegionData?.map((el)=>el?.regionFilter)}                        
                    onChange={changeRegion}
                  />
                  <Field
                    name="city"
                    component={FormMultiSelect}
                    textField="city"
                    valueField="city"
                    label="CITY"
                    placeholder="City"
                    data={storeCityData}
                    onChange={changeCity}
                  />
                  <Field
                    name="province"
                    component={FormMultiSelect}
                    textField="province"
                    valueField="province"
                    label="PROVINCE"
                    placeholder="Province"
                    data={storeProvinceData}
                    onChange={changeProvince}
                  />
                </div>
                <div className="search-btn">
                  <Button
                    type={"submit"}
                    className='k-primary'
                    icon="search"
                  >
                    SEARCH
                  </Button>
                  <Button
                    type={"reset"}
                    className='k-secondary'
                    icon="reset"
                    onClick={() => {
                      setTimeout(() => {
                        formRenderProps.onFormReset();
                        setDefaultYear("");
                        setDefaultWeek("");
                      }, 0);
                    }}
                  >
                    RESET
                  </Button>
                </div>
              </FormElement>
            </React.Fragment>
          )}
        />
      </div>


    </React.Fragment>
  )
}

export default CommonFilters
