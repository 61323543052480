import * as React from 'react';
import './index.scss';

import { FieldWrapper } from '@progress/kendo-react-form';
import {
    Input, MaskedTextBox, NumericTextBox,
    Checkbox, ColorPicker, Switch, RadioGroup,
    Slider, SliderLabel, TextArea
} from '@progress/kendo-react-inputs';
import {
    DatePicker, TimePicker, DateTimePicker,
    DateRangePicker, DateInput
} from '@progress/kendo-react-dateinputs';
import { Upload } from '@progress/kendo-react-upload';
import { Label, Error, Hint, FloatingLabel } from '@progress/kendo-react-labels';
import { DropDownList, AutoComplete, ComboBox, MultiColumnComboBox } from '@progress/kendo-react-dropdowns';

import { withValueField } from './fields/dropdown';
import MultiSelectCombo from './fields/multiselectcombo';
import { PasswordInput } from './fields/input';

const ComboBoxWithValue = withValueField(ComboBox);

export const FormPasswordInput = (fieldRenderProps) => {
    console.log(fieldRenderProps);
    const { validationMessage, style, touched, label, id, valid, disabled, blurHandler, hint, required, type, optional, wrapperStyle, ...others } = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';

    const labelStyle = required ? { color: 'blue', opacity: disabled ? 0.65 : undefined } : undefined;
    const requiredLabel = required ? label + '*' : label;

    return (
        <FieldWrapper style={wrapperStyle}>
            <Label editorId={id} editorDisabled={disabled} optional={optional} style={labelStyle}>
                {requiredLabel}
            </Label>
            <div className={'k-form-field-wrap'}>
                <PasswordInput
                    valid={valid}
                    type={type}
                    id={id}
                    name={undefined}
                    disabled={disabled}
                    ariaDescribedBy={`${hintId} ${errorId}`}
                    required={required}
                    {...others}
                />
                {
                    showHint &&
                    <Hint id={hintId}>{hint}</Hint>
                }
                {
                    showValidationMessage &&
                    <Error id={errorId}>{validationMessage}</Error>
                }
            </div>
        </FieldWrapper>
    );
};

export const FormInput = (fieldRenderProps) => {
    const { validationMessage, touched, label, id, valid, disabled, blurHandler, hint, required, type, optional, wrapperStyle,setAPICall,apiCall, ...others } = fieldRenderProps;


    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';

    const labelStyle = required ? { color: 'blue', opacity: disabled ? 0.65 : undefined } : undefined;
    const requiredLabel = required ? label + '*' : label;

    const customHandler = e => {
        fieldRenderProps.onBlur(e);
        blurHandler && blurHandler(e);
        // console.log("hereBlur",blurHandler)
        // setAPICall(true)
    };

    return (
        <FieldWrapper style={wrapperStyle}>
            <Label editorId={id} editorDisabled={disabled} optional={optional} style={labelStyle}>
                {requiredLabel}
            </Label>
            <div className={'k-form-field-wrap'}>
                <Input
                    valid={valid}
                    type={type}
                    id={id}
                    disabled={disabled}
                    ariaDescribedBy={`${hintId} ${errorId}`}
                    required={required}
                    {...others}
                    onBlur={customHandler}
                    
                />
                {
                    showHint &&
                    <Hint id={hintId}>{hint}</Hint>
                }
                {
                    showValidationMessage &&
                    <Error id={errorId}>{validationMessage}</Error>
                }
            </div>
        </FieldWrapper>
    );
};

export const FormRadioGroup = (fieldRenderProps) => {
    const { validationMessage, touched, id, label, valid, disabled, hint, required, visited, modified, wrapperStyle, ...others } = fieldRenderProps;
    const editorRef = React.useRef(null);

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';
    const labelId = label ? `${id}_label` : '';

    const labelStyle = required ? { color: 'blue', opacity: disabled ? 0.65 : undefined } : undefined;
    const requiredLabel = required ? label + '*' : label;

    return (
        <FieldWrapper style={wrapperStyle}>
            <Label id={labelId} editorRef={editorRef} editorId={id} editorDisabled={disabled} style={labelStyle}>
                {requiredLabel}
            </Label>
            <RadioGroup
                id={id}
                ariaDescribedBy={`${hintId} ${errorId}`}
                ariaLabelledBy={labelId}
                valid={valid}
                disabled={disabled}
                ref={editorRef}
                required={required}
                {...others}
            />
            {
                showHint &&
                <Hint id={hintId}>{hint}</Hint>
            }
            {
                showValidationMessage &&
                <Error id={errorId}>{validationMessage}</Error>
            }
        </FieldWrapper>
    );
};

export const FormNumericTextBox = (fieldRenderProps) => {
    const { validationMessage, touched, label, id, valid, disabled, blurHandler, hint, required, wrapperStyle, ...others } = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';

    const labelStyle = required ? { color: 'blue', opacity: disabled ? 0.65 : undefined } : undefined;
    const requiredLabel = required ? label + '*' : label;

    const customHandler = e => {
        fieldRenderProps.onBlur(e);
        blurHandler && blurHandler(e);
    };

    return (
        <FieldWrapper style={wrapperStyle}>
            <Label editorId={id} editorDisabled={disabled} style={labelStyle}>
                {requiredLabel}
            </Label>
            <NumericTextBox
                ariaDescribedBy={`${hintId} ${errorId}`}
                valid={valid}
                id={id}
                disabled={disabled}
                required={required}
                {...others}
                onBlur={customHandler}
            />
            {
                showHint &&
                <Hint id={hintId}>{hint}</Hint>
            }
            {
                showValidationMessage &&
                <Error id={errorId}>{validationMessage}</Error>
            }
        </FieldWrapper>
    );
};

export const FormCheckbox = (fieldRenderProps) => {
    const { validationMessage, touched, id, valid, disabled, hint, optional, label, visited, modified, wrapperStyle, ...others } = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';

    return (
        <FieldWrapper style={wrapperStyle}>
            <Checkbox
                ariaDescribedBy={`${hintId} ${errorId}`}
                label={label}
                labelOptional={optional}
                valid={valid}
                id={id}
                disabled={disabled}
                {...others}
            />
            {
                showHint &&
                <Hint id={hintId}>{hint}</Hint>
            }
            {
                showValidationMessage &&
                <Error id={errorId}>{validationMessage}</Error>
            }
        </FieldWrapper>
    );
};

export const FormSwitch = (fieldRenderProps) => {
    const { validationMessage, touched, label, optional, id, valid, disabled, hint, required, wrapperStyle, ...others } = fieldRenderProps;
    const editorRef = React.useRef(null);

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';
    const labelId = label ? `${id}_label` : '';

    const labelStyle = required ? { color: 'blue', opacity: disabled ? 0.65 : undefined } : undefined;
    const requiredLabel = required ? label + '*' : label;

    return (
        <FieldWrapper style={wrapperStyle}>
            <Label
                id={labelId}
                editorRef={editorRef}
                editorId={id}
                editorDisabled={disabled}
                optional={optional}
                style={labelStyle}
            >
                {requiredLabel}
            </Label>
            <Switch
                ref={editorRef}
                ariaLabelledBy={labelId}
                ariaDescribedBy={`${hintId} ${errorId}`}
                valid={valid}
                id={id}
                disabled={disabled}
                required={required}
                {...others}
            />
            {
                showHint &&
                <Hint id={hintId}>{hint}</Hint>
            }
            {
                showValidationMessage &&
                <Error id={errorId}>{validationMessage}</Error>
            }
        </FieldWrapper>
    );
};

export const FormMaskedTextBox = (fieldRenderProps) => {
    const { validationMessage, touched, label, id, valid, hint, required, optional, wrapperStyle, ...others } = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';

    const labelStyle = required ? { color: 'blue', opacity: others.disabled ? 0.65 : undefined } : undefined;
    const requiredLabel = required ? label + '*' : label;

    return (
        <FieldWrapper style={wrapperStyle}>
            <Label editorId={id} optional={optional} style={labelStyle}>
                {requiredLabel}
            </Label>
            <div className={'k-form-field-wrap'}>
                <MaskedTextBox
                    ariaDescribedBy={`${hintId} ${errorId}`}
                    valid={valid}
                    id={id}
                    required={required}
                    {...others}
                />
                {
                    showHint &&
                    <Hint id={hintId}>{hint}</Hint>
                }
                {
                    showValidationMessage &&
                    <Error id={errorId}>{validationMessage}</Error>
                }
            </div>
        </FieldWrapper>
    );
};

export const FormTextArea = (fieldRenderProps) => {
    const { validationMessage, touched, label, id, valid, hint, required, disabled, optional, wrapperStyle, ...others } = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';

    const labelStyle = required ? { color: 'blue', opacity: disabled ? 0.65 : undefined } : undefined;
    const requiredLabel = required ? label + '*' : label;

    return (
        <FieldWrapper style={wrapperStyle}>
            <Label editorId={id} optional={optional} labelStyle={labelStyle}>{requiredLabel}</Label>
            <TextArea
                valid={valid}
                id={id}
                disabled={disabled}
                ariaDescribedBy={`${hintId} ${errorId}`}
                required={required}
                {...others}
            />
            {
                showHint &&
                <Hint id={hintId}>{hint}</Hint>
            }
            {
                showValidationMessage &&
                <Error id={errorId}>{validationMessage}</Error>
            }
        </FieldWrapper>
    );
};

export const FormColorPicker = (fieldRenderProps) => {
    const { validationMessage, touched, label, id, valid, disabled, hint, required, wrapperStyle, ...others } = fieldRenderProps;
    const editorRef = React.useRef(null);

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';
    const labelId = label ? `${id}_label` : '';

    const labelStyle = required ? { color: 'blue', opacity: disabled ? 0.65 : undefined } : undefined;
    const requiredLabel = required ? label + '*' : label;

    return (
        <FieldWrapper style={wrapperStyle}>
            <Label
                id={labelId}
                editorRef={editorRef}
                editorId={id}
                editorDisabled={disabled}
                style={labelStyle}
            >
                {requiredLabel}
            </Label>
            <ColorPicker
                ariaLabelledBy={labelId}
                ariaDescribedBy={`${hintId} ${errorId}`}
                ref={editorRef}
                valid={valid}
                id={id}
                disabled={disabled}
                required={required}
                {...others}
            />
            {
                showHint &&
                <Hint id={hintId}>{hint}</Hint>
            }
            {
                showValidationMessage &&
                <Error id={errorId}>{validationMessage}</Error>
            }
        </FieldWrapper>
    );
};

export const FormSlider = (fieldRenderProps) => {
    const { validationMessage, touched, label, id, valid, disabled, hint, required, data, wrapperStyle, ...others } = fieldRenderProps;

    const editorRef = React.useRef(null);
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';
    const labelId = label ? `${id}_label` : '';

    const labelStyle = required ? { color: 'blue', opacity: disabled ? 0.65 : undefined } : undefined;
    const requiredLabel = required ? label + '*' : label;

    return (
        <FieldWrapper style={wrapperStyle}>
            <Label
                id={labelId}
                editorRef={editorRef}
                editorId={id}
                editorDisabled={disabled}
                style={labelStyle}
            >
                {requiredLabel}
            </Label>
            <Slider
                ariaLabelledBy={labelId}
                ariaDescribedBy={`${hintId} ${errorId}`}
                ref={editorRef}
                valid={valid}
                id={id}
                disabled={disabled}
                required={required}
                {...others}
            >
                {
                    data.map(value => (
                        <SliderLabel
                            title={value}
                            key={value}
                            position={value}
                        />
                    ))
                }
            </Slider>
            {
                showHint &&
                <Hint id={hintId}>{hint}</Hint>
            }
            {
                showValidationMessage &&
                <Error id={errorId}>{validationMessage}</Error>
            }
        </FieldWrapper>
    );
};

export const FormUpload = (fieldRenderProps) => {
    const { valid, value, id, optional, label, hint, required, validationMessage, touched, wrapperStyle, ...others } = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';
    const labelId = label ? `${id}_label` : '';

    const labelStyle = required ? { color: 'blue', opacity: others.disabled ? 0.65 : undefined } : undefined;
    const requiredLabel = required ? label + '*' : label;

    const onChangeHandler = (event) => {
        fieldRenderProps.onChange({ value: event.newState });
    };
    const onRemoveHandler = (event) => {
        fieldRenderProps.onChange({ value: event.newState });
    };

    return (
        <FieldWrapper style={wrapperStyle}>
            <Label id={labelId} editorId={id} optional={optional} style={labelStyle}>
                {requiredLabel}
            </Label>
            <Upload
                id={id}
                valid={valid}
                autoUpload={false}
                showActionButtons={false}
                multiple={false}
                files={value}
                onAdd={onChangeHandler}
                onRemove={onRemoveHandler}
                ariaDescribedBy={`${hintId} ${errorId}`}
                ariaLabelledBy={labelId}
                required={required}
                {...others}
            />
            {
                showHint &&
                <Hint id={hintId}>{hint}</Hint>
            }
            {
                showValidationMessage &&
                <Error id={errorId}>{validationMessage}</Error>
            }
        </FieldWrapper>
    );
};

export const FormDropDownList = (fieldRenderProps) => {
    const { validationMessage, touched, label, id, valid, disabled, hint, required, wrapperStyle, ...others } = fieldRenderProps;
    const editorRef = React.useRef(null);

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';
    const labelId = label ? `${id}_label` : '';

    const labelStyle = required ? { color: 'blue', opacity: disabled ? 0.65 : undefined } : undefined;
    const requiredLabel = required ? label + '*' : label;

    return (
        <FieldWrapper style={wrapperStyle}>
            <Label
                id={labelId}
                editorRef={editorRef}
                editorId={id}
                editorDisabled={disabled}
                style={labelStyle}
            >
                {requiredLabel}
            </Label>
            <DropDownList
                ariaLabelledBy={labelId}
                ariaDescribedBy={`${hintId} ${errorId}`}
                ref={editorRef}
                valid={valid}
                id={id}
                disabled={disabled}
                required={required}
                {...others}
            />
            {
                showHint &&
                <Hint id={hintId}>{hint}</Hint>
            }
            {
                showValidationMessage &&
                <Error id={errorId}>{validationMessage}</Error>
            }
        </FieldWrapper>
    );
};

export const FormAutoComplete = (fieldRenderProps) => {
    const { validationMessage, touched, label, id, valid, disabled, hint, required, wrapperStyle, ...others } = fieldRenderProps;
    const editorRef = React.useRef(null);

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';
    const labelId = label ? `${id}_label` : '';

    const labelStyle = required ? { color: 'blue', opacity: disabled ? 0.65 : undefined } : undefined;
    const requiredLabel = required ? label + '*' : label;

    return (
        <FieldWrapper style={wrapperStyle}>
            <Label
                id={labelId}
                editorRef={editorRef}
                editorId={id}
                editorDisabled={disabled}
                style={labelStyle}
            >
                {requiredLabel}
            </Label>
            <AutoComplete
                ariaLabelledBy={labelId}
                ariaDescribedBy={`${hintId} ${errorId}`}
                ref={editorRef}
                valid={valid}
                id={id}
                disabled={disabled}
                required={required}
                {...others}
            />
            {
                showHint &&
                <Hint id={hintId}>{hint}</Hint>
            }
            {
                showValidationMessage &&
                <Error id={errorId}>{validationMessage}</Error>
            }
        </FieldWrapper>
    );
};

export const FormComboBox = (fieldRenderProps) => {
    const { validationMessage, touched, label, id, valid, disabled, hint, required, wrapperStyle, skipFilter, ...others } = fieldRenderProps;
    const editorRef = React.useRef(null);

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';
    const labelId = label ? `${id}_label` : '';

    const labelStyle = required ? { color: 'blue', opacity: disabled ? 0.65 : undefined } : undefined;
    const requiredLabel = required ? label + '*' : label;

    return (
        <FieldWrapper style={wrapperStyle}>
            <Label id={labelId} editorRef={editorRef} editorId={id} editorDisabled={disabled} style={labelStyle}>
                {requiredLabel}
            </Label>
            <ComboBoxWithValue
                ariaLabelledBy={labelId}
                ariaDescribedBy={`${hintId} ${errorId}`}
                ref={editorRef}
                valid={valid}
                id={id}
                disabled={disabled}
                required={required}
                {...others}
            />
            {
                showHint &&
                <Hint id={hintId}>{hint}</Hint>
            }
            {
                showValidationMessage &&
                <Error id={errorId}>{validationMessage}</Error>
            }
        </FieldWrapper>
    );
};

export const FormMultiSelect = (fieldRenderProps) => {
    const { validationMessage, touched, label, id, valid, disabled, hint, required, wrapperStyle, ...others } = fieldRenderProps;
    const editorRef = React.useRef(null);

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';
    const labelId = label ? `${id}_label` : '';

    const labelStyle = required ? { color: 'blue', opacity: disabled ? 0.65 : undefined } : undefined;
    const requiredLabel = required ? label + '*' : label;

    let value = fieldRenderProps.value;
    if (fieldRenderProps.tag && fieldRenderProps.data?.length && value?.length) {
        value = value.map(e => {
            return fieldRenderProps.data.find(item => item[fieldRenderProps.tag] === e[fieldRenderProps.tag]);
        });
    }

    return (
        <FieldWrapper style={wrapperStyle}>
            <Label id={labelId} editorRef={editorRef} editorId={id} editorDisabled={disabled} style={labelStyle}>
                {requiredLabel}
            </Label>
            <MultiSelectCombo
                ariaLabelledBy={labelId}
                ariaDescribedBy={`${hintId} ${errorId}`}
                ref={editorRef}
                valid={valid}
                id={id}
                disabled={disabled}
                required={required}
                {...others}
                value={value || []}
            />
            {
                showHint &&
                <Hint id={hintId}>{hint}</Hint>
            }
            {
                showValidationMessage &&
                <Error id={errorId}>{validationMessage}</Error>
            }
        </FieldWrapper>
    );
};

export const FormMultiColumnComboBox = (fieldRenderProps) => {
    const { validationMessage, touched, label, id, valid, disabled, hint, required, wrapperStyle, skipFilter, ...others } = fieldRenderProps;
    const editorRef = React.useRef(null);

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';
    const labelId = label ? `${id}_label` : '';

    const labelStyle = required ? { color: 'blue', opacity: disabled ? 0.65 : undefined } : undefined;
    const requiredLabel = required ? label + '*' : label;

    return (
        <FieldWrapper style={wrapperStyle}>
            <Label id={labelId} editorRef={editorRef} editorId={id} editorDisabled={disabled} style={labelStyle}>
                {requiredLabel}
            </Label>
            <MultiColumnComboBox
                ariaLabelledBy={labelId}
                ariaDescribedBy={`${hintId} ${errorId}`}
                ref={editorRef}
                valid={valid}
                id={id}
                disabled={disabled}
                required={required}
                {...others}
            />
            {
                showHint &&
                <Hint id={hintId}>{hint}</Hint>
            }
            {
                showValidationMessage &&
                <Error id={errorId}>{validationMessage}</Error>
            }
        </FieldWrapper>
    );
};

export const FormDatePicker = (fieldRenderProps) => {
    const {
        validationMessage, touched, label, id, valid, required,
        disabled, hint, wrapperStyle, hintDirection, ...others
    } = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';
    const labelId = label ? `${id}_label` : '';

    const labelStyle = required ? { color: 'blue', opacity: disabled ? 0.65 : undefined } : undefined;
    const requiredLabel = required ? label + '*' : label;

    return (
        <FieldWrapper style={wrapperStyle}>
            <Label id={labelId} editorId={id} editorDisabled={disabled} style={labelStyle}>
                {requiredLabel}
            </Label>
            <div className={'k-form-field-wrap'}>
                <DatePicker
                    ariaLabelledBy={labelId}
                    ariaDescribedBy={`${hintId} ${errorId}`}
                    valid={valid}
                    id={id}
                    disabled={disabled}
                    required={required}
                    {...others}
                />
                {
                    showHint &&
                    <Hint id={hintId} direction={hintDirection}>{hint}</Hint>
                }
                {
                    showValidationMessage &&
                    <Error id={errorId}>{validationMessage}</Error>
                }
            </div>
        </FieldWrapper>
    );
};

export const FormDateTimePicker = (fieldRenderProps) => {
    const { validationMessage, touched, label, id, valid, disabled, hint, required, wrapperStyle, ...others } = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';
    const labelId = label ? `${id}_label` : '';

    const labelStyle = required ? { color: 'blue', opacity: disabled ? 0.65 : undefined } : undefined;
    const requiredLabel = required ? label + '*' : label;

    return (
        <FieldWrapper style={wrapperStyle}>
            <Label id={labelId} editorId={id} editorDisabled={disabled} style={labelStyle}>
                {requiredLabel}
            </Label>
            <DateTimePicker
                ariaLabelledBy={labelId}
                ariaDescribedBy={`${hintId} ${errorId}`}
                valid={valid}
                id={id}
                disabled={disabled}
                required={required}
                {...others}
            />
            {
                showHint &&
                <Hint id={hintId}>{hint}</Hint>
            }
            {
                showValidationMessage &&
                <Error id={errorId}>{validationMessage}</Error>
            }
        </FieldWrapper>
    );
};

export const FormTimePicker = (fieldRenderProps) => {
    const { validationMessage, touched, label, id, valid, disabled, hint, required, wrapperStyle, ...others } = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';
    const labelId = label ? `${id}_label` : '';

    const labelStyle = required ? { color: 'blue', opacity: disabled ? 0.65 : undefined } : undefined;
    const requiredLabel = required ? label + '*' : label;

    return (
        <FieldWrapper style={wrapperStyle}>
            <Label id={labelId} editorId={id} editorDisabled={disabled} style={labelStyle}>
                {requiredLabel}
            </Label>
            <TimePicker
                ariaLabelledBy={labelId}
                ariaDescribedBy={`${hintId} ${errorId}`}
                valid={valid}
                id={id}
                disabled={disabled}
                required={required}
                {...others}
            />
            {
                showHint &&
                <Hint id={hintId}>{hint}</Hint>
            }
            {
                showValidationMessage &&
                <Error id={errorId}>{validationMessage}</Error>
            }
        </FieldWrapper>
    );
};

export const FormDateInput = (fieldRenderProps) => {
    const { validationMessage, touched, label, id, valid, disabled, hint, required, wrapperStyle, ...others } = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';
    const labelId = label ? `${id}_label` : '';

    const labelStyle = required ? { color: 'blue', opacity: disabled ? 0.65 : undefined } : undefined;
    const requiredLabel = required ? label + '*' : label;

    return (
        <FieldWrapper style={wrapperStyle}>
            <Label id={labelId} editorId={id} editorDisabled={disabled} style={labelStyle}>
                {requiredLabel}
            </Label>
            <DateInput
                ariaLabelledBy={labelId}
                ariaDescribedBy={`${hintId} ${errorId}`}
                valid={valid}
                id={id}
                disabled={disabled}
                required={required}
                {...others}
            />
            {
                showHint &&
                <Hint id={hintId}>{hint}</Hint>
            }
            {
                showValidationMessage &&
                <Error id={errorId}>{validationMessage}</Error>
            }
        </FieldWrapper>
    );
};

export const FormDateRangePicker = (fieldRenderProps) => {
    const { validationMessage, touched, label, id, valid, disabled, hint, required, wrapperStyle, ...others } = fieldRenderProps;
    const editorRef = React.useRef(null);

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';
    const labelId = label ? `${id}_label` : '';

    const labelStyle = required ? { color: 'blue', opacity: disabled ? 0.65 : undefined } : undefined;
    const requiredLabel = required ? label + '*' : label;

    return (
        <FieldWrapper style={wrapperStyle}>
            <Label id={labelId} editorRef={editorRef} editorId={id} editorDisabled={disabled} style={labelStyle}>
                {requiredLabel}
            </Label>
            <DateRangePicker
                ariaLabelledBy={labelId}
                ariaDescribedBy={`${hintId} ${errorId}`}
                ref={editorRef}
                valid={valid}
                id={id}
                disabled={disabled}
                required={required}
                {...others}
            />
            {
                showHint &&
                <Hint id={hintId}>{hint}</Hint>
            }
            {
                showValidationMessage &&
                <Error id={errorId}>{validationMessage}</Error>
            }
        </FieldWrapper>
    );
};

export const FormFloatingNumericTextBox = (fieldRenderProps) => {
    const { validationMessage, touched, label, id, valid, disabled, hint, required, optional, value, wrapperStyle, ...others } = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';

    const labelStyle = required ? { color: 'blue', opacity: disabled ? 0.65 : undefined } : undefined;
    const requiredLabel = required ? label + '*' : label;

    return (
        <FieldWrapper style={wrapperStyle}>
            <FloatingLabel
                optional={optional}
                editorValue={value}
                editorId={id}
                editorDisabled={disabled}
                label={requiredLabel}
                style={labelStyle}
            >
                <NumericTextBox
                    ariaDescribedBy={`${hintId} ${errorId}`}
                    value={value}
                    valid={valid}
                    id={id}
                    disabled={disabled}
                    required={required}
                    {...others}
                />
            </FloatingLabel>
            {
                showHint &&
                <Hint id={hintId}>{hint}</Hint>
            }
            {
                showValidationMessage &&
                <Error id={errorId}>{validationMessage}</Error>
            }
        </FieldWrapper>
    );
};